import axios, { AxiosResponse } from 'axios';
import store from 'cache/store';
import authApi from './authApi';
import { login, logout } from 'features/authSlice';
import alertMsg from 'components/alert-message/alert-message';
import { toggleShowFirstNotice } from 'features/msgSystemSlice';
let isRefreshing = false;

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true,
});

axiosClient.interceptors.request.use(
    (config) => {
        const {
            token: { accessToken },
        } = store.getState().auth;

        if (accessToken && config.url != '/authentication/refreshToken.aspx') {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

let refreshTokenRequest: any = null;
// Add a response interceptor
axiosClient.interceptors.response.use(
    async (response: AxiosResponse) => {
        if (response.data.status === 401) {
            const originalRequest = response.config;
            if (originalRequest.url == '/authentication/refreshToken.aspx') {
                // debugger;
                logoutToken();
                return response;
            }

            if (!isRefreshing) {
                refreshTokenRequest = refreshTokenRequest ? refreshTokenRequest : handleTokenExpired();
                const { data } = await refreshTokenRequest;

                refreshTokenRequest = null;
                isRefreshing = false; //set dafault to use next refresh token
                if (data) {
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                }

                return axios(originalRequest);
            }
        }
        return response;
    },
    (error) => {
        if (error.response.status === 409) {
            // đăng nhập nhiều thiết bị
            store.dispatch(logout());
            alertMsg('Đăng nhập trùng lặp, bạn đã đăng xuất', 'multi-device');
        }
        return Promise.reject(error.response);
    }
);

const handleTokenExpired = () =>
    new Promise(async (resolve) => {
        try {
            const {
                token: { refreshToken },
            } = store.getState().auth;

            const { data } = await authApi.refreshToken({ refreshToken });
            resolve(data);
            if (data.status === -1) {
                logoutToken();
            } else {
                store.dispatch(login(data.data));
            }
            isRefreshing = true;
        } catch (error) {
            logoutToken();
        }
    });

const logoutToken = () => {
    store.dispatch(logout());
    store.dispatch(toggleShowFirstNotice(false));
    alertMsg('Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại!', 'expired', true);
};
export default axiosClient;
