import { css } from '@emotion/css';
import authApi from 'apis/authApi';
import userApi from 'apis/userApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import { colors } from 'constants/theme';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
const linkIframe = process.env.REACT_APP_VERIFY_PHONE_URL;
interface ErrorInput {
    password: string;
    rePassword: string;
}
interface Props {
    onClose: () => void;
}

export default function ForgotPassword(props: Props) {
    const { onClose } = props;
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const configFirbase = useRef<string>('');
    const [loading, setLoading] = useState(false);
    const [tokenChangePass, setTokenChangePass] = useState('');

    const [showPass, setShowPass] = useState(false);
    const [showRePass, setShowRePass] = useState(false);
    const [errorInput, setErrorInput] = useState({} as ErrorInput);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [showIframe, setShowIframe] = useState(true);

    useEffect(() => {
        // nhan thong bao tu iframe
        window.addEventListener('message', eventOnMessageIframe);

        return () => {
            window.removeEventListener('message', eventOnMessageIframe);
        };
    }, []);

    useEffect(() => {
        const getConfigFirebase = async () => {
            try {
                const { data } = await userApi.getFirebaseConfig({});
                if (!data.msg) {
                    configFirbase.current = JSON.stringify(data['data'][0]);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getConfigFirebase();
    }, []);

    const eventOnMessageIframe = (event: any) => {
        const data = event.data;
        if (data.action === 'CHECK_ACCOUNT') {
            checkPhone(data.params);
        } else if (data.action === 'TOKEN_VERIFY_DONE') {
            verifyUser(data.params.phoneNumber, data.params.tokenFB, data.params.username);
        }
    };

    const checkPhone = async (payload: { username: string; phone: string }) => {
        setLoading(true);
        try {
            const { data } = await authApi.checkUserValid(payload);
            if (!data.msg) {
                let dataVerifyPhone = {
                    action: 'INIT_VERIFY',
                    params: {
                        verifiedPhone: 0, // 0: chưa verify, 1: đã verify
                        phoneNumber: payload.phone,
                        arrConfigFirebase: configFirbase.current,
                    },
                };
                if (iframeRef.current) {
                    iframeRef.current.contentWindow?.postMessage(dataVerifyPhone, '*');
                    iframeRef.current.contentWindow?.postMessage({ action: 'CHECK_PHONE_VALID', phone: payload.phone }, '*');
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const verifyUser = async (phone: string, tokenFB: string, username: string) => {
        console.log(tokenFB);
        setLoading(true);
        try {
            const { data } = await authApi.verifyUser({ username, phone, token: tokenFB });
            if (!data.msg) {
                setTokenChangePass(data.data.token);
                setShowIframe(false);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changeInputPass = (value: string) => {
        setPassword(value);
        setErrorInput((value) => ({
            ...value,
            password: '',
        }));
    };

    const changeInputRePass = (value: string) => {
        setRePassword(value);
        setErrorInput((value) => ({
            ...value,
            rePassword: '',
        }));
    };

    const handleChangePass = async () => {
        if (!password.trim()) {
            setErrorInput((value) => ({
                ...value,
                password: 'Vui lòng nhập trường này',
            }));
            return;
        }
        if (!rePassword.trim()) {
            setErrorInput((value) => ({
                ...value,
                rePassword: 'Vui lòng nhập trường này',
            }));
            return;
        }
        if (password.trim().length < 6) {
            setErrorInput((value) => ({
                ...value,
                password: 'Mật khẩu tối thiểu 6 ký tự',
            }));
            return;
        }

        if (password.trim() !== rePassword.trim()) {
            setErrorInput((value) => ({
                ...value,
                rePassword: 'Mật khẩu nhập lại không đúng',
            }));
            return;
        }

        setLoading(true);
        try {
            const { data } = await authApi.changePassLogin({ token: tokenChangePass, newPassword: password });
            if (!data.msg) {
                toast.success('Thay đổi mật khẩu thành công');
                onClose();
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.wrapTransfer}>
                <div className={styles.heading}>
                    <h6 className={styles.title}>Khôi phục mật khẩu</h6>
                    <button type="button" className={styles.btnClose} onClick={onClose}>
                        <IoMdClose size={36} color={colors.primary} />
                    </button>
                </div>
                <div className={`${styles.wrapIframe} ${showIframe ? 'show' : ''}`}>
                    <iframe src={`${linkIframe}?style=1`} title="alb88 verify phone" ref={iframeRef}></iframe>

                    <div className={`${styles.formChangePass} from-change-pass`}>
                        <div className={styles.inputWrap}>
                            <label htmlFor="newPass">Mật Khẩu Mới</label>
                            <input
                                type={!showPass ? 'password' : 'text'}
                                onChange={(e) => changeInputPass(e.target.value)}
                                id="newPass"
                                placeholder="Nhập mật khẩu mới"
                                className={`input-common ${styles.input}`}
                            />
                            <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                                {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                            </div>
                            <div className={styles.errorInput}>{errorInput['password'] && <small>{errorInput['password']}</small>}</div>
                        </div>
                        <div className={styles.inputWrap}>
                            <label htmlFor="reNewPass">Mật Lại Khẩu Mới</label>
                            <input
                                type={!showRePass ? 'password' : 'text'}
                                onChange={(e) => changeInputRePass(e.target.value)}
                                id="reNewPass"
                                placeholder="Nhập lại mật khẩu mới"
                                className={`input-common ${styles.input}`}
                            />
                            <div className={styles.togglePass} onClick={() => setShowRePass(!showRePass)}>
                                {showRePass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                            </div>
                            <div className={styles.errorInput}>{errorInput['rePassword'] && <small>{errorInput['rePassword']}</small>}</div>
                        </div>
                        <button type="submit" className="btn-secondary btn-change-pass" onClick={handleChangePass}>
                            Cập Nhật
                        </button>
                    </div>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    wrapTransfer: css`
        background: ${colors.blackText};
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        padding: 16px;
        color: ${colors.white};
        position: relative;
    `,
    title: css`
        flex: 1;
        padding-left: 30px;
        text-align: center;
        font-size: 20px;
    `,
    btnClose: css`
        width: 40px;
        height: 40px;
        cursor: pointer;
    `,
    heading: css`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    `,
    gretting: css`
        span {
            color: ${colors.primary};
        }
    `,
    desc: css`
        padding: 8px;
        text-align: center;
    `,
    form: css`
        color: ${colors.white};
    `,
    inputWrap: css`
        position: relative;
        padding-bottom: 20px;
        &.border {
            border-top: 1px solid ${colors.borderTable};
            padding-top: 16px;
        }
        label {
            font-size: 14px;
            display: block;
            margin-bottom: 6px;
            font-weight: 300;
        }
    `,
    input: css`
        width: 100%;
    `,

    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.red};
        }
    `,
    wrapSendPhone: css`
        display: flex;
        gap: 12px;
        .btn-send-phone {
            flex: 0 0 100px;
            min-width: 100px;
            border-radius: 8px;
        }
    `,
    wrapIframe: css`
        width: 100%;
        height: 320px;
        background: ${colors.blackText};
        border: 1px solid transparent;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &.show {
            iframe {
                opacity: 1;
                pointer-events: initial;
            }
            .from-change-pass {
                opacity: 0;
                pointer-events: none;
            }
        }
        iframe {
            border: none;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
        }
    `,
    formChangePass: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .btn-change-pass {
            padding: 12px 0;
            border-radius: 8px;
            cursor: pointer;
            margin-top: 16px;
        }
    `,
    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 42px;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
};
