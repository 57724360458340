import { StastGameType, StatsGeneralCol } from 'models/statisticModel';
import React from 'react';
import styles from './stats-general-table.style';
import Stats3rdGameTalbe from './Stats3rdGameTable';
interface Props {
    tableData: StatsGeneralCol[];
    newList: any;
    gameList: any;
    rebateList: any;
    minigameList: any;
    tabSelect: StastGameType | 'tongquan';
    is3rdGame: boolean;
}

function StatsGeneralTable(props: Props) {
    const { tableData, newList, gameList, rebateList, is3rdGame, minigameList, tabSelect } = props;

    const checkDrawBorder = (item: StatsGeneralCol, index: number) => {
        if (item.id === 'mienbac') {
            if (index % 2 !== 0) {
                return styles.borderNone;
            } else {
                return styles.borderRight;
            }
        } else if (item.id === 'mientrung') {
            if (index % 2 === 0) {
                return styles.borderNone;
            } else {
                return styles.borderRight;
            }
        } else {
            if (index % 2 === 0) {
                return styles.borderRight;
            } else {
                return styles.borderNone;
            }
        }
    };

    const convertStastGame = (key: string, id: string) => {
        let stastItem = `${formatNumberSeperate(newList[key]) || 0} / ${formatNumberSeperate(newList[`${key}win`]) || 0}`;
        if (tabSelect === 'xosotruyenthong') {
            return `${stastItem} / ${formatNumberSeperate(rebateList[key]) || 0}`;
        } else if (tabSelect === 'minigame') {
            return `${formatNumberSeperate(minigameList[key]) || 0} / ${formatNumberSeperate(minigameList[`${key}win`]) || 0}`;
        } else {
            return `${stastItem}`;
        }
    };

    const convertStatsTotal = (key: string, isProfit?: boolean) => {
        if (tabSelect !== 'minigame') {
            const groupType = key.replace('total', '');
            const gameMapList = [...tableData].filter((item) => item.id === groupType);
            let totalBet = 0,
                totalWin = 0,
                totalRebet = 0;
            gameMapList.forEach((game) => {
                totalBet += newList[game.key];
                totalWin += newList[`${game.key}win`];
                totalRebet += rebateList[game.key];
            });
            if (isProfit) {
                let profitValue = totalWin - totalBet;
                if (tabSelect === 'xosotruyenthong') {
                    profitValue += totalRebet;
                }

                return formatNumberSeperate(profitValue, true) || 0;
            }
            let totalCol = `${formatNumberSeperate(totalBet) || 0} / ${formatNumberSeperate(totalWin) || 0} `;
            if (tabSelect === 'xosotruyenthong') {
                totalCol = `${totalCol} / ${formatNumberSeperate(totalRebet) || 0}`;
            }
            return totalCol;
        } else {
            if (isProfit) {
                return formatNumberSeperate(minigameList.win - minigameList.tz, true) || 0;
            } else {
                return `${formatNumberSeperate(minigameList['tz']) || 0} / ${formatNumberSeperate(minigameList['win']) || 0}`;
            }
        }
    };

    const getProfitOwnGame = (key: string) => {
        if (tabSelect !== 'minigame') {
            let profit = newList[`${key}win`] - newList[key];
            if (tabSelect === 'xosotruyenthong') {
                profit += rebateList[key];
            }
            return formatNumberSeperate(profit, true);
        } else {
            return formatNumberSeperate(minigameList[`${key}win`] - minigameList[key], true) || 0;
        }
    };

    const formatNumberSeperate = (num: number | string, hightlight?: boolean) => {
        if (num || Number(num) === 0) {
            let classString = '';
            if (hightlight) {
                if (Number(num) < 0) {
                    classString = 'text-error';
                } else if (Number(num) > 0) {
                    classString = 'text-success';
                }
                return <span className={classString}>{new Intl.NumberFormat().format(Number(num))}</span>;
            } else {
                return new Intl.NumberFormat().format(Number(num));
            }
        }
        return null;
    };
    return (
        <div className="table-common" style={{ borderTop: 'none', borderRadius: '0' }}>
            <div className="row">
                <div className={`${styles.group50} ${styles.borderRight}`}>
                    <div className={`title ${styles.col} ${styles.flex25}`}>Loại </div>
                    <div className={`title ${styles.col} ${styles.flex50} text-center`}>Cược / Trúng {tabSelect === 'xosotruyenthong' && '/ Chiết Khấu'}</div>
                    <div className={`title ${styles.col} ${styles.flex25} text-center`}>Lợi nhuận</div>
                </div>
                <div className={`${styles.group50} ${styles.borderNone}`}>
                    <div className={`title ${styles.col} ${styles.flex25}`}>Loại </div>
                    <div className={`title ${styles.col} ${styles.flex50} text-center`}>Cược / Trúng {tabSelect === 'xosotruyenthong' && '/ Chiết Khấu'}</div>
                    <div className={`title ${styles.col} ${styles.flex25} text-center`}>Lợi nhuận</div>
                </div>
            </div>
            <div className="row" style={{ flexWrap: 'wrap' }}>
                {!is3rdGame ? (
                    tableData.map((item, index) => (
                        <React.Fragment key={item.key}>
                            {!item.key.includes('total') ? (
                                <div className={`${styles.group50} ${checkDrawBorder(item, index)}`}>
                                    <div className={`${styles.col} ${styles.flex25}`}>{item.label}</div>
                                    <div className={`${styles.col} ${styles.flex50} text-center`}>{convertStastGame(item.key, item.id)}</div>
                                    <div className={`${styles.col} ${styles.flex25} text-center`}>{getProfitOwnGame(item.key)}</div>
                                </div>
                            ) : (
                                <div className={styles.group100}>
                                    <div className={styles.group50}>
                                        <div className={`${styles.col} ${styles.flex25}`}>{item.label}</div>
                                        <div className={`${styles.col} ${styles.flex50} text-center`}>{convertStatsTotal(item.key)}</div>
                                        <div className={`${styles.col} ${styles.flex25} text-center`}>{convertStatsTotal(item.key, true)} </div>
                                    </div>
                                    <div className={styles.flex50}></div>
                                </div>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <Stats3rdGameTalbe gameList={gameList} checkDrawBorder={checkDrawBorder} tableData={tableData} />
                )}
            </div>
        </div>
    );
}

export default StatsGeneralTable;
