import { css } from '@emotion/css';
import globalStyle from 'constants/global.style';
import { msgAlertInput } from 'constants/messageAlert';
import { AgentMemberPayload } from 'models/agentModel';
import { UserInfoModel } from 'models/userInfoModel';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import accountStyles from './account-option.style';
import userApi from 'apis/userApi';
import { removeSeperateNumber } from 'utils/function';
import toast from 'react-hot-toast';
import moment from 'moment';
import LoadingIcon from 'components/loading/LoadingIcon';
const orderMemberList = [
    { value: 0, label: 'Thời gian đăng ký mới nhất' },
    { value: 1, label: 'Thời gian đăng ký cũ nhất' },
    { value: 2, label: 'Số dư ít nhất' },
    { value: 3, label: 'Số dư nhiều nhất' },
    { value: 4, label: 'Đăng nhập nhiều nhất' },
    { value: 5, label: 'Đăng nhập gần đây nhất' },
];
function AgentMember() {
    const {
        register,
        handleSubmit,

        control,
        formState: { errors },
    } = useForm<AgentMemberPayload>();
    const [orderData, setOrderData] = useState(0);
    const [memberList, setMemberList] = useState<UserInfoModel[] | null>(null);
    const [loading, setLoading] = useState(false);
    const getMemberAgentList: SubmitHandler<AgentMemberPayload> = async (values) => {
        const { samount, eamount } = values;
        if (samount) values.samount = removeSeperateNumber(samount);
        if (eamount) values.eamount = removeSeperateNumber(eamount);

        setLoading(true);
        const payload: AgentMemberPayload = {
            ...values,
            order: orderData,
            userarea: 0,
        };

        try {
            const { data } = await userApi.agentUserSearch(payload);
            if (!data.msg) {
                setMemberList(data.reslist);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Cấp Dưới</h6>
                <form onSubmit={handleSubmit(getMemberAgentList)}>
                    <div className={accountStyles.flexbox}>
                        <div className={`${accountStyles.groupInput} ${styles.flex30}`}>
                            <label htmlFor="">Tên Đăng Nhập</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('loginname', {
                                        maxLength: {
                                            value: 30,
                                            message: msgAlertInput.generic.maxLength(30),
                                        },
                                        pattern: {
                                            value: msgAlertInput.username.pattern,
                                            message: msgAlertInput.generic.noWhiteSpace,
                                        },
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder={msgAlertInput.username.invalid}
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['loginname'] && <small>{errors['loginname'].message || msgAlertInput.generic.require}</small>}</div>
                        </div>
                        <div className={`${accountStyles.groupInput} ${styles.flex30}`}>
                            <label htmlFor="">Sắp Xếp Theo</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={orderData} onChange={(e) => setOrderData(Number(e.target.value))}>
                                    {orderMemberList.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={`${styles.flex40} ${styles.groupAmount}`}>
                            <div className={accountStyles.groupInput}>
                                <label htmlFor="">Số Dư Từ</label>
                                <div className={accountStyles.wrapInput}>
                                    <Controller
                                        control={control}
                                        name="samount"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat allowNegative={false} className="input-common" thousandSeparator="," name={name} value={value} onChange={onChange} />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={accountStyles.groupInput}>
                                <label htmlFor="">Đến</label>
                                <div className={accountStyles.wrapInput}>
                                    <Controller
                                        control={control}
                                        name="eamount"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat allowNegative={false} className="input-common" thousandSeparator="," name={name} value={value} onChange={onChange} />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '30px', width: 'calc(calc(100% / 3) - 12px)' }}>
                        <button type="submit" className={`btn-primary ${accountStyles.btnSubmit}`}>
                            Tìm Kiếm
                        </button>
                    </div>
                </form>

                <div className="table-common">
                    <div className="row">
                        <p className="title col">Tên đăng nhập</p>
                        <p className="title col">Số dư</p>
                        <p className="title col">Đăng ký</p>
                        <p className="title col">Đăng nhập cuối</p>
                        <p className="title col">Lần đăng nhập</p>
                        <p className="title col">Cấp trên</p>
                    </div>
                    {memberList ? (
                        <>
                            {memberList.length > 0 ? (
                                memberList.map((mem) => (
                                    <div className="row" key={mem.id}>
                                        <p className="col">{mem.loginname}</p>
                                        <p className="col">{new Intl.NumberFormat().format(Number(mem.balance))}</p>
                                        <p className="col">{moment(mem.addtime).format('DD/MM/yyyy HH:mm')}</p>
                                        <p className="col">{mem.lastlogintime ? moment(mem.lastlogintime).format('DD/MM/yyyy HH:mm') : 'Chưa đăng nhập lần nào'}</p>
                                        <p className="col">{mem.logintimes}</p>
                                        <p className="col">{mem.param2}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    groupAmount: css`
        display: flex;
        gap: 12px;
    `,
    flex30: css`
        flex: 0 0 calc(30% - 32px);
    `,
    flex40: css`
        flex: 0 0 calc(40% - 32px);
    `,
};

export default AgentMember;
