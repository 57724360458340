import { colors } from 'constants/theme';
import styles from './login-header.style';
import Button from 'components/button/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormValuesLogin, LoginPayload } from 'models/authModel';
import toast from 'react-hot-toast';
import { DEVICE_INFO } from 'constants/common';
import { RootState, useAppSelector } from 'cache/store';
import { decode } from 'utils/base64';
import { useEffect, useState } from 'react';
import { VERSION } from 'config/app.config';

interface Props {
    onLogin: (payload: LoginPayload, showAlertSuccess: boolean, closeModal: boolean, rememberAccount?: boolean) => void;
    onShowAuth: () => void;
    onShowForgotPW: () => void;
}

function LoginHeader(props: Props) {
    const { onLogin, onShowAuth, onShowForgotPW } = props;
    const { rememberData, isLogged } = useAppSelector((state: RootState) => state.auth);

    const [rememberAccount, setRememberAccount] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const { handleSubmit, register, setFocus, setValue } = useForm<FormValuesLogin>();

    useEffect(() => {
        if (rememberData?.isRemember && rememberData?.account && !isLogged) {
            setValue('username', decode(rememberData.account).split('|')[0]);
            setValue('password', decode(rememberData.account).split('|')[1]);
            setRememberAccount(true);
        }
    }, [rememberData, isLogged, setValue]);

    const handleLoginForm: SubmitHandler<FormValuesLogin> = async (values) => {
        const { username, password } = values;
        if (!username.toLowerCase().trim()) {
            setFocus('username');
            toast.error('Vui lòng nhập tài khoản !');
            return;
        }
        if (!password) {
            setFocus('password');
            toast.error('Vui lòng nhập mật khẩu!');
            return;
        }
        const payload = {
            username,
            password,
            version: VERSION,
            di: DEVICE_INFO,
        };
        onLogin(payload, true, false, rememberAccount);
    };

    return (
        <form className={styles.right} onSubmit={handleSubmit(handleLoginForm)}>
            <div className={styles.bgForm}>
                <div className={styles.formLogin}>
                    <div className={styles.inputWrap}>
                        <img className={styles.icon} src={require('assets/images/icons/user_input.png')} alt="" />
                        <input {...register('username')} className={styles.input} type="text" placeholder="Tên Đăng Nhập" />
                        <div className={styles.remember}>
                            <input onChange={(e) => setRememberAccount(e.target.checked)} checked={rememberAccount} id="remember" type="checkbox" />
                            <label htmlFor="remember">Ghi nhớ</label>
                        </div>
                    </div>
                    <div className={styles.inputWrap}>
                        <img className={styles.icon} src={require('assets/images/icons/pw_input.png')} alt="" />
                        <input {...register('password')} className={styles.input} type={showPass ? 'text' : 'password'} placeholder="Mật khẩu" />
                        <button type="button" className={styles.togglePw} onClick={() => setShowPass(!showPass)}>
                            {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </button>
                    </div>
                    <button type="button" className={styles.forgetPw} onClick={onShowForgotPW}>
                        Quên mật khẩu?
                    </button>
                </div>
            </div>
            <div className={styles.btnGroup}>
                <Button text="Đăng Nhập" bg="none" borderColor="#927B7B" minWidth="112px" typeButton="submit" />
                <Button text="Đăng Ký" bg={colors['gradient-1']} borderColor="none" click={onShowAuth} minWidth="112px" />
            </div>
        </form>
    );
}

export default LoginHeader;
