import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const updateUserStyles = {
    wrapTransfer: css`
        background: ${colors.blackText};
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        padding: 16px;
        color: ${colors.white};
    `,
    title: css`
        flex: 1;
        padding-left: 30px;
        text-align: center;
        font-size: 20px;
    `,
    btnClose: css`
        width: 40px;
        height: 40px;
        cursor: pointer;
    `,
    heading: css`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    `,
    gretting: css`
        span {
            color: ${colors.primary};
        }
    `,
    desc: css`
        padding: 8px;
        text-align: center;
    `,
    form: css`
        color: ${colors.white};
    `,
    inputWrap: css`
        position: relative;
        padding-bottom: 20px;
        label {
            font-size: 14px;
            display: block;
            margin-bottom: 6px;
            font-weight: 300;
        }
    `,
    input: css`
        width: 100%;
    `,

    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.red};
        }
    `,
    textTOF: css`
        font-size: 14px;
        font-weight: 300;
        a {
            color: ${colors.yellow};
        }
    `,

    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 42px;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
};

export default updateUserStyles;
