import { RootState, useAppSelector } from 'cache/store';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './header-menu.style';
import { Game3rdMenuItem, MenuHeaderItem } from 'models/commonModel';
import HeaderMenuLottery from './HeaderMenuLottery';
import globalStyle from 'constants/global.style';
import alertMsg from 'components/alert-message/alert-message';

interface Props {
    onPlayGame: (game: Game3rdMenuItem) => void;
}

function HeaderMenu(props: Props) {
    const { onPlayGame } = props;
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { menuGame } = useAppSelector((state: RootState) => state.system);
    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const listMenuRef = useRef<any[]>([]);

    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');

    const [menuGameList, setMenuGameList] = useState<MenuHeaderItem[]>(menuGame);

    useEffect(() => {
        if (menuGame.length > 0) {
            const lotteryMenu: MenuHeaderItem = {
                name: 'Xổ Số',
                child: [],
                isMain: false,
                isLottery: true,
                path: '/lottery',
            };
            let newMenuGame = [...menuGame].filter((item) => !(item.child && item.child.length === 0));
            newMenuGame.splice(2, 0, lotteryMenu);

            setMenuGameList(newMenuGame);
        }
    }, [menuGame]);

    const onHoverMenuGame = (index: number, isHover: boolean) => {
        const listMenuSelect: HTMLDivElement = listMenuRef.current[index];
        if (listMenuSelect) {
            if (isHover) {
                (listMenuSelect.closest('.game-wrap') as HTMLDivElement).style.height = `${listMenuSelect.clientHeight}px`;
            } else {
                (listMenuSelect.closest('.game-wrap') as HTMLDivElement).style.height = '0';
            }
        }
    };

    const selectGame = (game: Game3rdMenuItem, index: number) => {
        if (!isLogged) {
            alertMsg('Vui lòng đăng nhập');
            onHoverMenuGame(index, false);
            return;
        }
        if (game.providercode === 'TX') {
            return;
        }
        onPlayGame(game);
    };

    const goToLobby = (game: MenuHeaderItem, index: number) => {
        if (game.gametype && game.gametype !== 'OT') {
            navigate(`/lobby?type=${game.gametype}`);
            onHoverMenuGame(index, false);
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container}>
                <ul className={styles.menuList}>
                    {menuGameList.map((item, index) => (
                        <li
                            className={classNames(styles.menuItem, { active: item.path === pathname || (pathname?.includes('/lobby') && item.gametype === type) })}
                            key={index}
                            onMouseEnter={() => onHoverMenuGame(index, true)}
                            onMouseLeave={() => onHoverMenuGame(index, false)}
                        >
                            {item.path ? (
                                <Link className={styles.menuLink} to={item.path}>
                                    {item.name}
                                </Link>
                            ) : (
                                <h6 className={styles.menuLink} onClick={() => goToLobby(item, index)}>
                                    {item.name}
                                    {item.gametype === 'LC' && <span className="label-hot">HOT</span>}
                                </h6>
                            )}

                            {/* game base list */}
                            {item.child && (
                                <div className={`${styles.baseGameHover} game-wrap`}>
                                    <div className={globalStyle.container}>
                                        <div className={`${styles.gameList} game-list`} ref={(el) => (listMenuRef.current[index] = el)}>
                                            {!item.isLottery ? (
                                                item.child.map((game, i) => (
                                                    <div className={`${styles.gameItem} ${game.providercode === 'TX' ? styles.gameMaintain : ''}`} key={i} onClick={() => selectGame(game, index)}>
                                                        {game.providercode === 'TX' && <div className="comming-soon">COMMING SOON</div>}
                                                        <img src={game.iconpc} alt="" />
                                                        <p className={styles.gameName}>{game.name}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <HeaderMenuLottery onCloseMenu={onHoverMenuGame} menuIndex={index} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <svg className={styles.svgBorder} height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
                <rect className={styles.lineSvg} height="100%" width="100%" />
            </svg>
        </div>
    );
}

export default HeaderMenu;
