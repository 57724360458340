import globalStyle from 'constants/global.style';
import LotterySidebar from './LotterySidebar';
import styles from './lottery-page.style';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
    FaceLottery,
    IssueResultWinOpenNumber,
    LotteryInterface,
    LotteryRecord,
    LotterySub,
    LotteryTicket,
    PrizeLotteryResponse,
    TicketConfirmLotteryData,
    TypeInputLottery,
} from 'models/lotteryModel';
import { checkIsFastLottery, checkIsTypeLotteryMienBac, cloneObject, extractTypeInNumbers, getLotteryFace, removeSeperateNumber } from 'utils/function';
import lotteryApi from 'apis/lotteryApi';
import toast from 'react-hot-toast';
import LotteryWinNumber from './lottery-win-number/LotteryWinNumber';
import classNames from 'classnames';
import { LotteryGame } from 'models/lotteryListModel';
import { LotteryMenu } from 'interfaces/lottery-menu';
import LotteryCountDown from 'components/lottery-countdown/LotteryCountDown';
import LotteryTypeInput from './lottery-type-input/LotteryTypeInput';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { TbArrowBigDownFilled } from 'react-icons/tb';
import { PiLightningFill } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import LotteryTicketsTable from './LotteryTicketsTable';
import { checkMB_DeDacBiet_3CangDacBiet, genBetSummary, genTicketInfo, mapPrizeIntoFaceLottery, updateMethodIdWithinBet1kBeforeSubmit, validateRule1PercentOnOriginalTickets } from './lotteryFunc';
import { FiCheckCircle } from 'react-icons/fi';
import Modal from 'components/modal/Modal';
import ConfirmBetLottery from './ConfirmBetLottery';
import { ltOpenDay } from 'constants/common';
import LoadingIcon from 'components/loading/LoadingIcon';
import ErrorDualNumLottery from './ErrorDualNumLottery';
import { useAppDispatch } from 'cache/store';
import authApi from 'apis/authApi';
import { cacheUserInfo } from 'features/authSlice';
import LotteryHistoryTable from './LotteryHistoryTable';
import { InfoPaginate } from 'models/commonModel';
import userApi from 'apis/userApi';
import BetRecord from 'components/bet-record/BetRecord';
import LotteryButtonCountDown from 'components/lottery-countdown/LotteryButtonCountDown';
import NumberLottery from 'components/number-lottery/NumberLottery';
import SwitchButton from 'components/switch-button/SwitchButton';
import { appName } from 'config/app.config';
import { colors } from 'constants/theme';

interface RateBetOption {
    label: string;
    value: string;
    type: 'MB' | 'MN';
}

const openFastLT = ['CSSSC', 'Jz45', 'PHP', 'SSCBF', 'CqSSC', 'FASTMB'];
const rateBetList: RateBetOption[] = [
    {
        label: '27k - 99.5k',
        value: '0|99.50',
        type: 'MB',
    },
    {
        label: '21.75k - 80k',
        value: '21750|80.0',
        type: 'MB',
    },
    {
        label: '18k - 99.5k',
        value: '0|99.50',
        type: 'MN',
    },
    {
        label: '14.5k - 80k',
        value: '14500|80.0',
        type: 'MN',
    },
];
let idIntervalShowResult: any;
interface TimeIssueCountDown {
    openTime: string;
    endTime: string;
    serverTime: string;
}

function LotteryPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const ltId: string = searchParams.get('ltId') || '50'; //mặc định xs nhanh 1s

    const dispatch = useAppDispatch();

    const tableHistoryRef = useRef<HTMLDivElement>(null);

    const [lotteryIdActive, setLotteryIdActive] = useState(0);
    const [lotteryInfo, setLotteryInfo] = useState<LotteryGame>({} as LotteryGame);
    const [winNumberList, setWinNumberList] = useState<IssueResultWinOpenNumber[]>([]);

    //main tab
    const [typeBet, setTypeBet] = useState<LotteryInterface>({} as LotteryInterface);
    const [faceLottery, setFaceLottery] = useState<FaceLottery>({ methodList: {}, methodidInDBList: {}, method1kList: {}, content: [] });

    //sub tab
    const [subTypeBetList, setSubTypeBetList] = useState<LotterySub[]>([]); //danh sách các loại cược. vd: lô 2 số, lô 2 số đầu, lô 3 số...
    const [subTypeBetActive, setSubTypeBetActive] = useState<LotterySub>({} as LotterySub);

    const [typeInputBet, setTypeInputBet] = useState<TypeInputLottery[]>([]); // danh sách loại nhập số cược: chọn số, chọn số nhanh, nhập số

    const [timeCount, setTimeCount] = useState<TimeIssueCountDown>({} as TimeIssueCountDown); //thời gian count down
    const [issue, setIssue] = useState('');
    const [amountBet, setAmountBet] = useState('1'); //cấp số nhân lần cược
    const [ticketList, setTicketList] = useState<LotteryTicket[]>([]); // danh sách vé cược
    const [userPrizeInit, setUserPrizeInit] = useState<PrizeLotteryResponse[]>([]); //tỉ lệ thắng mỗi loại cược

    const [showConfirmBet, setShowConfirmBet] = useState(false);
    const [ticketsConfirm, setTicketsConfirm] = useState<TicketConfirmLotteryData>({} as TicketConfirmLotteryData); //thông tin popup confirm cược

    const [validDualNumList, setValidDualNumList] = useState<any[]>([]);
    const [historyBetList, setHistoryBetList] = useState<LotteryRecord[]>([]);
    const [infoPageRecord, setInfoPageRecord] = useState<InfoPaginate | null>(null);

    const [loading, setLoading] = useState(false);
    const [idShowDetailBet, setIdShowDetailBet] = useState<string | null>(null);
    const [showEffectOpenResult, setShowEffectOpenResult] = useState(false); //state toggle mở thưởng

    const [isSelectBet1k, setIsSelectBet1k] = useState(false);
    const [showRateBet, setShowRateBet] = useState(false); //show/hide select kiểu cược
    const [rateBetOptions, setRateBetOptions] = useState<RateBetOption[]>(rateBetList);
    const [changeTypeInput, setChangeTypeInput] = useState(true);

    useEffect(() => {
        if (ltId) {
            setLotteryIdActive(Number(ltId));
            const faceLotteryInit = getLotteryFace(Number(ltId));

            //get thông tin đài cược
            LotteryMenu.forEach((group) => {
                group.submenu.forEach((item) => {
                    if (item.lotteryID === Number(ltId)) {
                        setLotteryInfo(item);
                        const isLTOneSec = item.isOneSec;
                        initLottery(item.lotteryType, ltId, faceLotteryInit, isLTOneSec);
                    }
                });
            });

            //filter kiểu cược
            let newRateBetOptions = [];
            if (checkIsTypeLotteryMienBac(Number(ltId))) {
                newRateBetOptions = rateBetList.filter((item) => item.type === 'MB');
            } else {
                newRateBetOptions = rateBetList.filter((item) => item.type === 'MN');
            }
            setRateBetOptions(newRateBetOptions);
        }
    }, [ltId]);

    useEffect(() => {
        if (lotteryIdActive) {
            handleRemoveTicket([]);
        }
    }, [lotteryIdActive]);

    useEffect(() => {
        if (faceLottery.content.length > 0) {
            setTypeBet(faceLottery.content[0]);
        }
    }, [faceLottery]);

    //thay đổi phương thức cược khi user thay đổi kiểu cược
    useEffect(() => {
        if (Object.keys(typeBet).length > 0) {
            if (typeBet.subTabs) {
                setSubTypeBetList(typeBet.subTabs);
            }
        }
    }, [typeBet]);

    //listener thay đổi kiểu nhập khi user thay đổi phương thức cược
    useEffect(() => {
        if (subTypeBetList.length > 0) {
            const subTypeBetActive = subTypeBetList.find((item) => item.active);
            if (subTypeBetActive) {
                setTypeInputBet(subTypeBetActive.typeInput);

                setSubTypeBetActive(subTypeBetActive);
                if (changeTypeInput) {
                    // toggleBet1kMode(false)
                    setIsSelectBet1k(false);
                }
            }
        }
    }, [subTypeBetList]);

    useEffect(() => {
        if (Object.keys(subTypeBetActive).length > 0) {
            const isFastLT = checkIsFastLottery(lotteryIdActive);
            if (!isFastLT && subTypeBetActive.typeIputID === 'lo-2-so' && !isSelectBet1k) {
                setShowRateBet(true);
            } else {
                setShowRateBet(false);
            }
        }
    }, [subTypeBetActive, lotteryIdActive]);

    //init lottery
    const initLottery = async (lotteryType: string, ltId: string, faceContent: LotteryInterface[], isLTOneSec: boolean) => {
        const payload = {
            lotteryId: Number(ltId),
            flag: lotteryType,
        };
        setLoading(true);
        try {
            const { data } = await lotteryApi.lotteryInit(payload);
            if (!data.msg) {
                const userPrize: PrizeLotteryResponse[] = JSON.parse(data.userprize);
                const newFaceLottery: FaceLottery = mapPrizeIntoFaceLottery(faceLottery, userPrize, faceContent);
                setFaceLottery({ ...newFaceLottery, content: faceContent });
                setUserPrizeInit(userPrize);

                const timeData = {
                    openTime: data.cursitem.opentime,
                    endTime: data.cursitem.endtime,
                    serverTime: data.servertime || '',
                };

                setIssue(data.cursitem.issue);
                setTimeCount(timeData);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            // danh sách lịch sử mở thưởng
            if (isLTOneSec) {
                getOpenWinOneSec();
            } else {
                getLotteryWinOpenNumber();
            }

            //lấy danh sách lịch sử cược
            getRecordList();

            setLoading(false);
        }
    };

    const getRecordList = async () => {
        const payload = {
            lotteryid: Number(ltId),
            num: 10,
        };
        try {
            const { data } = await lotteryApi.lotteryRecordBean(payload);
            if (!data.msg) {
                mapRecordHistoryBet(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const mapRecordHistoryBet = (data: any) => {
        const { curpage, totalpage, totalrecord } = data;
        setInfoPageRecord({ curpage, totalpage, totalrecord });

        const recordList: LotteryRecord[] = [];
        data.reslist.forEach((item: any) => {
            recordList.push({
                id: item.id,
                issue: item.issue,
                lotteryName: item.lotteryname,
                lotteryMethod: item.methodname,
                bettime: item.bettime,
                codes: item.codes,
                amount: item.amount,
                times: item.multiple,
                total: item.betnumber,
                status: item.statusname,
                bettype: item.bettype,
                lotteryid: item.lotteryid,
                methodid: item.methodid,
                desc: item.betcontent,
                point: item.point.toString(),
                ratio: item.odd,
                remark: item.remark,
            });
        });
        setHistoryBetList(recordList);
    };

    const getLotteryWinOpenNumber = async () => {
        const payload = {
            id: Number(ltId),
            num: 10, // số lượng
        };
        try {
            const { data } = await lotteryApi.lotteryWinOpenNumber(payload);
            if (!data.msg) {
                const resultList: IssueResultWinOpenNumber[] = [];

                data.reslist.forEach((item: { issue: string; winnumber: string }) => {
                    resultList.push({
                        issue: item.issue,
                        code: item.winnumber.split(',').filter((num) => num !== ''),
                    });
                });
                setWinNumberList(resultList);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getOpenWinOneSec = async () => {
        const payload = {
            lotteryid: Number(ltId),
            num: 5,
        };
        try {
            const { data } = await lotteryApi.lotteryMMCWinOpenNumber(payload);
            if (!data.msg) {
                if (data.winnumberlist[0].winnumber === '') {
                    setWinNumberList([]);
                    return;
                }

                const resultList: IssueResultWinOpenNumber[] = [];

                data.winnumberlist.forEach((item: { issue: string; winnumber: string }) => {
                    resultList.push({
                        issue: item.issue,
                        code: item.winnumber.split(',').filter((num) => num !== ''),
                    });
                });
                setWinNumberList(resultList);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeChanelLottery = (ltId: number) => {
        setLotteryIdActive(ltId);
        setSearchParams({ ltId: String(ltId) });
    };

    const changeFaceLotterty = (typeInputId: string) => {
        const faceContent = getLotteryFace(Number(ltId));
        const newFaceLottery: FaceLottery = mapPrizeIntoFaceLottery(faceLottery, userPrizeInit, faceContent);
        let newTypebet = newFaceLottery.content.find((item) => item.typeIputID === typeInputId);

        if (newTypebet) {
            setTypeBet(newTypebet);
        }
    };

    const changeSubTypeBet = (typeInputId: string) => {
        if (typeBet.subTabs) {
            const subList: LotterySub[] = cloneObject(typeBet.subTabs);
            const newSubTypeBet = subList.map((item) => {
                if (item.typeIputID === typeInputId) {
                    const typeInputReset = item.typeInput.map((typeInputItem) => {
                        return {
                            ...typeInputItem,
                            layout: typeInputItem.layout.map((layoutItem) => ({
                                ...layoutItem,
                                selectedNums: [],
                                btns: layoutItem.btns.map((itembtn) => ({ ...itembtn, active: false })),
                            })), // reset các số đã chọn
                        };
                    });
                    return { ...item, active: true, typeInput: typeInputReset, amount: 0 };
                }
                setChangeTypeInput(true);
                return { ...item, active: false, amount: 0 };
            });
            setSubTypeBetList(newSubTypeBet);
        }
    };

    const resetBtnSelect = (item: any) => {
        item.layout.map((elm: any) => {
            return elm.btns.map((btn: any) => {
                return (btn.active = false);
            });
        });
    };

    const handleChangeTypebet = (inputType: 'pick' | 'quick-pick' | 'type-in') => {
        const newTypeInputBet = typeInputBet.map((item) => {
            if (item.typeIputID === inputType) {
                return {
                    ...item,
                    active: true,
                    layout: item.layout.map((layoutItem) => ({
                        ...layoutItem,
                        selectedNums: [],
                        btns: layoutItem.btns.map((itembtn) => ({ ...itembtn, active: false })),
                    })), // reset các số đã chọn
                };
            }
            return { ...item, active: false };
        });

        //reset tổng các số đã chọn
        if (subTypeBetActive.amount > 0) {
            setSubTypeBetActive((currentValue: LotterySub) => ({ ...currentValue, amount: 0 }));
        }
        setTypeInputBet(newTypeInputBet);
    };

    const handleChangeAmount = (type: 'minus' | 'plus' | 'input', value?: string) => {
        if (type === 'input' && value) {
            setAmountBet(value);
        }
        if (type === 'minus') {
            setAmountBet((value) => {
                const numberMulti = removeSeperateNumber(value);
                if (Number(numberMulti) <= 1) return numberMulti;
                return String(Number(numberMulti) - 1);
            });
        }
        if (type === 'plus') {
            setAmountBet((value) => {
                const numberMulti = removeSeperateNumber(value);

                if (Number(value) < 2000) return String(Number(numberMulti) + 1);
                return numberMulti;
            });
        }
    };

    const handleChangeTypeInput = (inputData: TypeInputLottery, amount: number, isValid: boolean, inputType: 'pick' | 'quick-pick' | 'type-in') => {
        const newTypeInputBet = typeInputBet.map((item) => {
            if (item.typeIputID === inputType) {
                return inputData;
            }
            return item;
        });
        const newSubTypeBetList = subTypeBetList.map((subType) => {
            if (subType.active) {
                const newSubTypeBetActive = {
                    ...subTypeBetActive,
                    amount,
                    betBtnActive: isValid,
                    typeInput: newTypeInputBet,
                };
                setSubTypeBetActive(newSubTypeBetActive);
                return newSubTypeBetActive;
            }
            return subType;
        });
        setChangeTypeInput(false);
        setSubTypeBetList(newSubTypeBetList);
        setTypeInputBet(newTypeInputBet);
    };

    const addTicket = () => {
        const isXienTruot = subTypeBetActive.isXienTruot;
        const ticketInfo = genTicketInfo(subTypeBetActive, typeInputBet);
        const prize = subTypeBetActive.dyprize?.prize;

        if (!Number.isInteger(Number(removeSeperateNumber(amountBet)))) {
            toast.error('Số nhân lần cược phải là số nguyên');
            return false;
        }
        if (!ticketInfo.curcodes.length) {
            toast.error('Vé không hợp lệ!');
            return false;
        }

        if (ticketInfo.picktype === 'pick') {
            // eslint-disable-next-line array-callback-return
            typeInputBet.map((item) => {
                resetBtnSelect(item);
            });
            if (checkValidTicket(ticketInfo.curcodes.join('|'), prize)) {
                addTicketToFaceLottery(ticketInfo.curcodes.join('|'), ticketInfo.tickettype);
            } else {
                toast.error('Vé không hợp lệ!');
                return false;
            }
        }
        if (ticketInfo.picktype === 'quick-pick') {
            if (isXienTruot) {
                if (checkValidTicket(ticketInfo.curcodes.join('&'), prize)) {
                    addTicketToFaceLottery(ticketInfo.curcodes.join('|'), ticketInfo.tickettype);
                } else {
                    toast.error('Vé không hợp lệ!');
                    return false;
                }
            } else {
                if (checkValidTicket(ticketInfo.curcodes.join(','), prize)) {
                    addTicketToFaceLottery(ticketInfo.curcodes.join('|'), ticketInfo.tickettype);
                } else {
                    toast.error('Vé không hợp lệ!');
                    return false;
                }
            }
        }
        if (ticketInfo.picktype === 'type-in') {
            if (isXienTruot) {
                ticketInfo.curcodes[0] = ticketInfo.curcodes[0].split(',').join(';');
            }
            let digitsFormat = subTypeBetActive.digits.split(',');
            let allTypeInNums = extractTypeInNumbers(ticketInfo.curcodes[0]);
            if (allTypeInNums.length) {
                let isValidLength = allTypeInNums.filter((num) => num && num.length === Number(digitsFormat[0])).length === allTypeInNums.length;
                if (!isValidLength) {
                    toast.error('Vé không hợp lệ!');

                    return false;
                }
                if (Number(digitsFormat[1]) > 0) {
                    let isValidQuantity = allTypeInNums.length % Number(digitsFormat[1]) === 0;
                    if (!isValidQuantity) {
                        toast.error('Vé không hợp lệ!');

                        return false;
                    }
                }
            } else {
                toast.error('Vé không hợp lệ!');

                return false;
            }
            if (checkValidTicket(ticketInfo.curcodes[0], prize)) {
                addTicketToFaceLottery(ticketInfo.curcodes[0], ticketInfo.tickettype);
            } else {
                toast.error('Vé không hợp lệ!');
                return false;
            }
        }
        return true;
    };

    const selectBetNow = () => {
        if (addTicket()) {
            onSelectBet();
        }
    };

    const addTicketToFaceLottery = (curcodes: string, tickettype: string) => {
        const ticket = genTicket(curcodes, tickettype);
        const newTickets = [...ticketList, ticket];
        setAmountBet('1');
        setTicketList(newTickets);

        resetAfterAddTicket();
    };

    const resetAfterAddTicket = () => {
        const newTypeInputBet = typeInputBet.map((item) => {
            if (item.active) {
                return {
                    ...item,
                    layout: item.layout.map((layoutItem) => ({
                        ...layoutItem,
                        selectedNums: [],
                        btns: layoutItem.btns.map((itembtn) => ({ ...itembtn, active: false })),
                    })), // reset các số đã chọn
                };
            }
            return item;
        });

        //reset tổng các số đã chọn
        if (subTypeBetActive.amount > 0) {
            setSubTypeBetActive((currentValue: LotterySub) => ({ ...currentValue, amount: 0 }));
        }
        setTypeInputBet(newTypeInputBet);
    };

    const checkValidTicket = (codes: string, prize: string) => {
        const curmid = subTypeBetActive.methodID;
        if (ticketList.length === 0) {
            return true;
        }
        let valid = false;
        for (const ticket of ticketList) {
            if (ticket.codes === codes && ticket.methodID === curmid && ticket.dyprize.prize === prize) {
                valid = false;
                break;
            } else {
                valid = true;
            }
        }
        return valid;
    };

    const genTicket = (curcodes: string, tickettype: string, ppTxcl: boolean = false) => {
        const subTabData = cloneObject(subTypeBetActive);

        const curMoney: number = ppTxcl
            ? subTabData.number * subTabData.payprizenum
            : subTabData.amount *
              subTabData.number *
              //kiểm tra kiểu cược mb: 21.75 ăn 80, mn + mt : 14.5 ăn 80
              (Number(subTabData.dyprize.point) !== 0 ? Number(subTabData.dyprize.point) : subTabData.payprizenum);
        const curTotal: number = ppTxcl ? 1 : subTypeBetActive.amount;
        return {
            type: tickettype,
            codes: curcodes,
            money: curMoney,
            trTitle: typeBet.title,
            stTitle: subTypeBetActive.title,
            methodID: subTypeBetActive.methodID,
            dyprize: cloneObject(subTypeBetActive.dyprize),
            rebate: subTypeBetActive.rebate,
            total: curTotal,
            times: Number(removeSeperateNumber(amountBet)),
            isBet1k: isSelectBet1k,
            methodid1k: isSelectBet1k ? cloneObject(faceLottery.method1kList[subTypeBetActive.methodID]).methodid1k : null,
        };
    };

    const handleRemoveTicket = (ticketList: LotteryTicket[]) => {
        setTicketList(ticketList);
    };

    const onSelectBet = () => {
        // if (ticketList.length === 0) {
        //     toast.error('Chưa có vé cược');
        //     return;
        // }
        let ticketsData: TicketConfirmLotteryData = {
            title: lotteryInfo.title,
            issue,
            time: new Date(),
            issueList: [],
            raiseBet: false, // nuôi số
            isOneSec: false,
            activeLotteryId: lotteryIdActive,
            ableToCopyOnRegions: ltOpenDay[new Date().getDay()].search(String(lotteryIdActive)) > -1,
            isPingPong: false,
            violateRule1PercentMethodIds: validateRule1PercentOnOriginalTickets(ticketList, faceLottery),
        };
        setTicketsConfirm(ticketsData);
        setShowConfirmBet(true);
    };

    const confirmBet = () => {
        setLoading(true);
        checkMB_DeDacBiet_3CangDacBiet(false, lotteryIdActive, issue, ticketList, (data: { passed: boolean; validateInfo?: any[] }) => {
            if (data.passed) {
                submitBet();
            } else {
                // show vé cược không hợp lệ kép bằng
                setShowConfirmBet(false);
                setValidDualNumList(data.validateInfo || []);
            }
            setLoading(false);
        });
    };

    const submitBet = async () => {
        const isFastLTOneSec = lotteryInfo.isOneSec; //kiểm tra số xổ 1 giây
        const isFastLT = checkIsFastLottery(lotteryIdActive);

        let issueBet = isFastLTOneSec ? '1' : issue;

        const betParams = genBetSummary(lotteryIdActive, issueBet, ticketList);
        let betPamramsAfterCheckBet1k = updateMethodIdWithinBet1kBeforeSubmit(betParams);
        setLoading(true);
        try {
            const { data } = await lotteryApi.submitMultiple(betPamramsAfterCheckBet1k, isFastLT);
            if (!data.msg && !data.message) {
                toast.success('Đặt cược thành công');

                //reset danh sách vé cược
                setTicketList([]);

                refreshBalance();

                // lấy danh sách lịch sử cược
                setTimeout(() => {
                    getRecordList();
                }, 2000);

                if (data.openwinlist) {
                    // lấy lịch sử mở thưởng xs 1 giây
                    if (isFastLTOneSec) {
                        getOpenWinOneSec();
                    }
                }
            } else {
                toast.error(data.message || data.msg);
            }
            //close modal xác nhận cược
            setShowConfirmBet(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshBalance = async () => {
        try {
            const { data } = await authApi.getUserInfo();
            if (!data.errorCode) {
                dispatch(cacheUserInfo(data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changePageHistoryBet = async (page: number) => {
        const payload: any = {
            lotteryid: lotteryIdActive,
            curpage: infoPageRecord?.curpage,
            num: page,
        };
        try {
            const { data } = await userApi.gameRecordSearch(payload, 'inputPage');
            if (!data.msg) {
                mapRecordHistoryBet(data);
                scrollToTableHistoryBet();
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const scrollToTableHistoryBet = () => {
        if (tableHistoryRef.current) {
            const rootEl = document.getElementById('appRoot');
            if (rootEl) {
                rootEl.scrollTo({
                    top: tableHistoryRef.current.offsetTop, // scroll to head table history bet
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleCancelBet = async (recordId: string) => {
        setLoading(true);
        try {
            const { data } = await lotteryApi.lotteryCancelBet({ id: recordId });
            if (!data.msg) {
                toast.success('Huỷ cược thành công');

                setIdShowDetailBet(null);
                getRecordList();
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const showTimerResult = () => {
        setShowEffectOpenResult(true);
        let pourTime: number = (+new Date(timeCount.openTime.replace(/-/g, '/')) - +new Date(timeCount.endTime.replace(/-/g, '/'))) / 1000;
        idIntervalShowResult && clearInterval(idIntervalShowResult);
        idIntervalShowResult = setInterval(() => {
            pourTime--;
            if (pourTime < 0) {
                clearInterval(idIntervalShowResult);
                readCurrentIssue();
            }
        }, 1000);
    };

    const readCurrentIssue = async () => {
        try {
            const { data } = await lotteryApi.lotteryCurrentIssue({ lotteryid: lotteryIdActive });
            const timeData = {
                openTime: data.opentime,
                endTime: data.saleend,
                serverTime: data.nowtime || '',
            };

            if (issue !== data.issue && openFastLT.indexOf(lotteryInfo.lotteryType) > -1) {
                getOpenRecord(issue, 0);
            }
            setIssue(data.issue);
            setTimeCount(timeData);
        } catch (error) {
            console.log(error);
        } finally {
            setShowEffectOpenResult(false);
        }
    };

    const getOpenRecord = async (curIssue: string, requestNum: number) => {
        requestNum++;

        if (requestNum > 5) {
            return;
        }
        const payload = {
            issue: curIssue,
            lotteryid: lotteryIdActive,
        };
        try {
            const { data } = await lotteryApi.getOpenWinRecored(payload);
            if (!data.msg) {
                const newResult = {
                    issue: data.issue,
                    code: data.code,
                };
                setWinNumberList((list) => [newResult, ...list]);
            } else {
                setTimeout(() => {
                    getOpenRecord(curIssue, requestNum);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggleBet1kMode = (is1k: boolean) => {
        const method1kData = cloneObject(faceLottery.method1kList[subTypeBetActive.methodID]);
        let dyprize = is1k ? cloneObject(method1kData.dyprize1k) : cloneObject(faceLottery.methodList[subTypeBetActive.methodidInDB || '']);
        dyprize = { ...dyprize, prize: parseFloat(dyprize.prize).toFixed(2) };

        const newSubTypeBet = {
            ...subTypeBetActive,
            dyprize,
            rebate: `0|${dyprize.prize}`,
            payprizenum: is1k ? Number(method1kData.payprizenum1k) : Number(method1kData.payprizenum),
        };

        newSubTypeBet.payprizeMoney = newSubTypeBet.payprizenum;

        setShowRateBet(is1k);

        setSubTypeBetActive(newSubTypeBet);

        setIsSelectBet1k(is1k);
    };

    const changeRebate = (value: string) => {
        const newSubTypeBetActive = cloneObject(subTypeBetActive);
        newSubTypeBetActive.dyprize = {
            point: value.split('|')[0],
            prize: value.split('|')[1],
        };
        if (Number(value.split('|')[0]) !== 0) {
            newSubTypeBetActive.payprizeMoney = Number(value.split('|')[0]);
        } else {
            delete newSubTypeBetActive.payprizeMoney;
        }
        setSubTypeBetActive(newSubTypeBetActive);
    };

    return (
        <>
            <div className={styles.wrapPage}>
                <div className={globalStyle.container} style={{ height: '100%' }}>
                    <div className={styles.wrapInner}>
                        <div className={styles.sidebar}>
                            <LotterySidebar lotteryIdActive={lotteryIdActive} onSelectGame={changeChanelLottery} />
                        </div>
                        <div className={styles.betContainer}>
                            {/* kết quả */}

                            <section className={styles.resultSection}>
                                {winNumberList.length > 0 ? (
                                    <LotteryWinNumber typeInputId={subTypeBetActive.typeIputID} winNumberList={winNumberList} ltId={lotteryIdActive} />
                                ) : (
                                    <div className={styles.noResult}>
                                        <p>Chưa có kết quả</p>
                                    </div>
                                )}
                                <div className={styles.issueWrap}>
                                    <div className={styles.resultSpecial}>
                                        <p>
                                            Giải Đặc Biệt: <span>{winNumberList.length > 0 ? winNumberList[0].issue : ''}</span>
                                        </p>
                                        <div className={styles.specialLotteryHistory}>
                                            {winNumberList.length > 0
                                                ? winNumberList[0].code[0].split('').map((num, index) => <NumberLottery value={Number(num)} isRun={showEffectOpenResult} key={index} />)
                                                : [1, 2, 3, 4, 5].map((num) => <span key={num}>?</span>)}
                                        </div>
                                    </div>
                                    <div className={styles.nextIssue}>
                                        <h6 className={styles.chanelLottery}>{lotteryInfo.title?.includes('XS') ? lotteryInfo.title?.replace('XS', 'Xổ Số') : lotteryInfo.title}</h6>
                                        {!lotteryInfo.isOneSec && (
                                            <>
                                                <p className={styles.timerCountLeft}>Thời gian đặt cược</p>
                                                <div className={styles.timerCountDown}>
                                                    <LotteryCountDown timeData={timeCount} onFinish={showTimerResult} />
                                                </div>
                                                <p className={styles.currentIssue}>
                                                    Lượt xổ: <span>{issue}</span>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                            {/* bảng cược */}
                            <section className={styles.betSection}>
                                {/* tab loại cược */}
                                <div className={styles.tabTypeBetList}>
                                    {faceLottery.content.map((tab, index) => (
                                        <div
                                            className={classNames(styles.tabTypeItem, { active: tab.typeIputID === typeBet.typeIputID })}
                                            key={index}
                                            onClick={() => changeFaceLotterty(tab.typeIputID)}
                                        >
                                            <span>{tab.title}</span>
                                        </div>
                                    ))}
                                </div>

                                {/* tab kiểu cược */}
                                <div className={styles.subTabTypeBetList}>
                                    {subTypeBetList.map((item) => (
                                        <div className={classNames(styles.subTabTypeBetItem, { active: item.active })} key={item.typeIputID} onClick={() => changeSubTypeBet(item.typeIputID)}>
                                            {item.title}
                                        </div>
                                    ))}
                                    <p style={{ color: colors.darkPrimary }}>{subTypeBetActive.desc}</p>
                                </div>

                                <div className={styles.inputBetWrap}>
                                    {/* tab kiểu nhập số */}
                                    <div className={styles.tabInputBetList}>
                                        {typeInputBet.map((item) => (
                                            <div className={classNames(styles.tabInputBetItem, { active: item.active })} key={item.typeIputID} onClick={() => handleChangeTypebet(item.typeIputID)}>
                                                <p>{item.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="">
                                        <LotteryTypeInput subTypeBet={subTypeBetActive} typeInputList={typeInputBet} onChange={handleChangeTypeInput} resetBet={resetAfterAddTicket} />
                                    </div>
                                </div>
                                <div className={styles.betAction}>
                                    <div className={styles.betInfo}>
                                        <div className={styles.betInfoLeft}>
                                            {subTypeBetActive.canBet1kMode && (
                                                <div className={styles.wrapBet1kAction}>
                                                    <SwitchButton isChecked={isSelectBet1k} onToggle={toggleBet1kMode} />
                                                    <p>Đặt cược 1k</p>
                                                </div>
                                            )}

                                            <p style={{ margin: '8px 0' }}>
                                                Tỉ lệ cược: <span>1</span> ăn <span>{Number(subTypeBetActive.dyprize?.prize).toFixed(2)}</span>
                                            </p>

                                            <p>
                                                Đã chọn <span>{subTypeBetActive.amount}</span> số. Số tiền cược{' '}
                                                {subTypeBetActive.payprizeMoney && (
                                                    <span>
                                                        {new Intl.NumberFormat().format(subTypeBetActive.amount * Number(removeSeperateNumber(amountBet)) * subTypeBetActive.payprizeMoney) || 0}
                                                    </span>
                                                )}
                                                {!subTypeBetActive.payprizeMoney && (
                                                    <span>{new Intl.NumberFormat().format(subTypeBetActive.amount * Number(removeSeperateNumber(amountBet)) * subTypeBetActive.payprizenum) || 0}</span>
                                                )}
                                            </p>
                                        </div>
                                        <div className={styles.betInfoRight}>
                                            {showRateBet && (
                                                <div className={styles.selectRateBet}>
                                                    <label htmlFor="rateBetId">Kiểu cược</label>
                                                    <select name="" id="rateBetId" onChange={(e) => changeRebate(e.target.value)}>
                                                        {rateBetOptions.map((item, index) => (
                                                            <option key={index} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            <div className={styles.betMulti}>
                                                <p>Cấp số nhân</p>
                                                <div className={styles.betTimesInput}>
                                                    <button type="button" onClick={() => handleChangeAmount('minus')}>
                                                        <FaMinus />
                                                    </button>
                                                    <NumericFormat allowNegative={false} thousandSeparator="," value={amountBet} onChange={(e) => handleChangeAmount('input', e.target.value)} />
                                                    <button type="button" onClick={() => handleChangeAmount('plus')}>
                                                        <FaPlus />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.betActionGroup}>
                                        <p className={styles.betNotice}>
                                            <span>*Chú ý:</span> Thành viên cược sát giờ đóng cược, hệ thống cần thời gian xử lý & vé cược sẽ được tính dựa trên Lượt Xổ ghi trên đơn cược. {appName} sẽ
                                            hoàn toàn không chịu trách nhiệm với bất cứ lý do nào cho việc cược sát giờ.
                                        </p>
                                        <div className={styles.betActionBtn}>
                                            <button type="button" className={globalStyle.btnSecondary} onClick={addTicket} disabled={subTypeBetActive.amount === 0}>
                                                <TbArrowBigDownFilled />
                                                <span>Thêm Vé Cược</span>
                                            </button>
                                            <button type="button" className={globalStyle.btnPrimary} disabled={subTypeBetActive.amount === 0} onClick={selectBetNow}>
                                                <PiLightningFill />
                                                <span>Cược Ngay</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* danh sách vé cược */}
                            <LotteryTicketsTable ticketList={ticketList} onRemoveTicket={handleRemoveTicket} />
                            <div className={styles.betActionGroup}>
                                <div className={styles.betMulti}>
                                    <p className={styles.totalMoneyBet}>
                                        Tổng tiền cược: <span>{new Intl.NumberFormat().format(ticketList.reduce((acc, ticket) => acc + ticket.money * ticket.times, 0))}</span>
                                    </p>
                                </div>
                                <div className={styles.betActionBtn}>
                                    <button type="button" className={`${globalStyle.btnPrimary} ${styles.btnBetAll}`} disabled={ticketList.length === 0} onClick={onSelectBet}>
                                        <FiCheckCircle />
                                        <span>Đặt Cược</span>
                                        <div className={styles.countdownBtn}>
                                            <LotteryButtonCountDown timeData={timeCount} noStyles />
                                        </div>
                                    </button>
                                </div>
                            </div>

                            {/* lịch sử cược */}
                            <div className="" ref={tableHistoryRef}>
                                <LotteryHistoryTable
                                    recordList={historyBetList}
                                    infoPage={infoPageRecord}
                                    onChangePage={changePageHistoryBet}
                                    onShowDetailRecord={(lotteryId: string) => setIdShowDetailBet(lotteryId)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isShow={showConfirmBet} onClose={() => setShowConfirmBet(false)} width="80%" maxWidth="1000px">
                <ConfirmBetLottery onClose={() => setShowConfirmBet(false)} onSubmit={confirmBet} ticketData={ticketsConfirm} ticketList={ticketList} />
            </Modal>

            {/* popup vượt quá số lần cược kép bằng */}
            <Modal isShow={validDualNumList.length > 0} onClose={() => setValidDualNumList([])}>
                <ErrorDualNumLottery validDualList={validDualNumList} onClose={() => setValidDualNumList([])} />
            </Modal>

            <Modal isShow={Boolean(idShowDetailBet)} onClose={() => setIdShowDetailBet(null)} closeClickBackdrop width="60%" maxWidth="900px">
                <BetRecord onClose={() => setIdShowDetailBet(null)} betRecordId={idShowDetailBet} onCancelBet={handleCancelBet} />
            </Modal>

            {loading && <LoadingIcon />}
        </>
    );
}

export default LotteryPage;
