import { css } from '@emotion/css';
import accountStyles from './account-option.style';
import DatePicker from 'components/date-picker/DatePicker';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import userApi from 'apis/userApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import gameListInterface from '../stats-general.interface';
import { colors } from 'constants/theme';
import { StastGameType, StatsGameGroup, StatsGeneralResponse } from 'models/statisticModel';
import classNames from 'classnames';
import StatsGeneralTable from 'components/table/StatsGeneralTable';
import { RootState, useAppSelector } from 'cache/store';
import InfoStatsGeneral from './InfoStatsGeneral';

const memberTypeList = [
    {
        label: 'Thành viên trực tiếp',
        value: 0,
    },
    {
        label: 'Cá nhân',
        value: 2,
    },
];

const noteList: any = {
    '0': 'Đây là dữ liệu của các thành viên cấp dưới trực tiếp.',
    '1': 'Đây là dữ liệu của cá nhân và toàn bộ thành viên cấp dưới.',
    '2': 'Đây là dữ liệu của chính cá nhân.',
};

interface StastTable {
    gamelst: any;
    rebateList: any;
    newlist: any;
    miniGame: any;
}

function StatsGeneral() {
    const bgTabActiveRef = useRef<HTMLDivElement>(null);
    const { menuGame } = useAppSelector((state: RootState) => state.system);
    const [startTime, setStartTime] = useState<Date | null>(new Date());
    const [endTime, setEndTime] = useState<Date | null>(new Date());
    const [memberTypeSelect, setMemberTypeSelect] = useState('0');
    const [noteStats, setNoteStats] = useState('');
    const [loading, setLoading] = useState(false);

    const [tabSelect, setTabSelect] = useState<StastGameType | 'tongquan'>('tongquan');
    const [is3rdGame, setIs3rdGame] = useState(false);

    const [gameGroupActive, setGameGroupActive] = useState<{ id: string; label: string; key: string; groupType: string }[]>([]);

    const [statsTableData, setStatsTableData] = useState<StastTable>({ gamelst: {}, rebateList: {}, newlist: {}, miniGame: {} });

    const [statsList, setStatsList] = useState<StatsGameGroup[]>([]);
    const [statsData, setStatsData] = useState<StatsGeneralResponse>({} as StatsGeneralResponse);

    useEffect(() => {
        let stats3rdGameList: StatsGameGroup[] = [];
        menuGame.forEach((item) => {
            if (item.child) {
                let gameGroup3rdItem: any[] = [];

                //record total
                const recordTotal = {
                    id: item.gametype || '',
                    label: 'Tổng',
                    key: `total${item.gametype || ''}`,
                };

                gameGroup3rdItem = item.child.map((gameChild) => {
                    return {
                        id: item.gametype || '',
                        label: gameChild.name,
                        key: gameChild.providercode,
                        groupType: gameChild.gametype,
                    };
                });
                const stats3rdItem: StatsGameGroup = {
                    id: (item.gametype || '') as any,
                    title: item.name,
                    is3rdGame: true,
                    gameGroup: [...gameGroup3rdItem, recordTotal],
                };
                stats3rdGameList = [...stats3rdGameList, stats3rdItem];
            }
        });
        stats3rdGameList = stats3rdGameList.filter((item) => item.gameGroup.length > 1);
        setStatsList([...gameListInterface.filter((tab) => tab.id !== 'dangian'), ...stats3rdGameList]);
    }, [menuGame]);

    useEffect(() => {
        const gameSelect = statsList.find((item) => item.id === tabSelect);
        if (gameSelect) {
            setGameGroupActive(gameSelect.gameGroup);
        }
    }, [tabSelect]);

    const searchStats = async () => {
        if (!startTime || !endTime) {
            toast.error('Vui lòng chọn thời gian!');
            return;
        }
        const payload = {
            userarea: memberTypeSelect,
            stime: moment(startTime).format('yyyy-MM-DD'),
            etime: moment(endTime).format('yyyy-MM-DD'),
        };
        setLoading(true);
        try {
            const { data }: any = await userApi.reportOverallSearch(payload);
            if (!data.msg) {
                const { newlist, gamelst, rebateList, miniGame } = data;
                setStatsTableData({ newlist, gamelst, rebateList, miniGame });
                setStatsData(data);

                setNoteStats(noteList[memberTypeSelect]);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changeTabType = (e: any, tabId: StastGameType | 'tongquan', is3rd: boolean) => {
        setTabSelect(tabId);
        setIs3rdGame(is3rd);
        const tabEl = e.target as HTMLParagraphElement;
        if (bgTabActiveRef.current) {
            bgTabActiveRef.current.style.left = `${tabEl.offsetLeft}px`;
            bgTabActiveRef.current.style.width = `${tabEl.offsetWidth}px`;
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Báo Cáo Tổng Quan</h6>
                <div className={accountStyles.flexbox}>
                    <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                        <label htmlFor="">Thành Viên</label>
                        <div className={accountStyles.wrapInput}>
                            <select className="input-common" value={memberTypeSelect} onChange={(e) => setMemberTypeSelect(e.target.value)}>
                                {memberTypeList.map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={`${styles.groupTime} ${accountStyles.flex50}`}>
                        <div className={accountStyles.groupInput} style={{ flex: 1 }}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon maxDate={new Date()} />
                            </div>
                        </div>
                        <div className={accountStyles.groupInput} style={{ flex: 1 }}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon maxDate={new Date()} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.actionGroup}>
                    <div style={{ width: 'calc(calc(100% / 3) - 12px)' }}>
                        <button type="submit" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={searchStats}>
                            Tìm Kiếm
                        </button>
                    </div>
                    {noteStats && (
                        <p className={styles.notice}>
                            <span>Lưu ý: </span>
                            {noteStats}
                        </p>
                    )}
                </div>

                <div className={styles.tabList}>
                    <div className={styles.squareActive} ref={bgTabActiveRef}></div>
                    <p className={classNames(styles.tabItem, { active: tabSelect === 'tongquan' })} onClick={(e) => changeTabType(e, 'tongquan', false)}>
                        Tổng Quan
                    </p>
                    {statsList.map((item) => (
                        <p className={classNames(styles.tabItem, { active: tabSelect === item.id })} key={item.id} onClick={(e) => changeTabType(e, item.id, item.is3rdGame)}>
                            {item.title}
                        </p>
                    ))}
                </div>
                {tabSelect === 'tongquan' ? (
                    <InfoStatsGeneral statsData={statsData} statsTableData={statsTableData} newReg={statsData.newreg} />
                ) : (
                    <StatsGeneralTable
                        is3rdGame={is3rdGame}
                        tableData={gameGroupActive}
                        newList={statsTableData.newlist}
                        gameList={statsTableData.gamelst}
                        rebateList={statsTableData.rebateList}
                        minigameList={statsTableData.miniGame}
                        tabSelect={tabSelect}
                    />
                )}
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    groupTime: css`
        display: flex;
        gap: 16px;
    `,
    tabList: css`
        display: flex;
        border-bottom: 1px solid ${colors.blue};
        position: relative;
    `,
    tabItem: css`
        width: fit-content;
        white-space: nowrap;
        padding: 12px;
        cursor: pointer;
        border-radius: 6px 6px 0 0;
        min-width: 85px;
        text-align: center;
        font-size: 15px;
        position: relative;
        &:hover {
            background: rgba(0, 0, 0, 0.5);
        }
        &.active {
            &:hover {
                background: ${colors.primary};
            }
        }
    `,
    squareActive: css`
        position: absolute;
        left: 0;
        top: 0;
        width: 95.75px;
        height: 100%;
        background: ${colors.primary};
        z-index: 0;
        border-radius: 6px 6px 0 0;
        transition: 0.25s;
    `,
    group50: css`
        display: flex;
        flex: 0 0 50%;
    `,
    group100: css`
        display: flex;
        flex: 0 0 100%;
    `,
    notice: css`
        font-style: italic;
        font-size: 14px;
        span {
            color: ${colors.red};
        }
    `,
    actionGroup: css`
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
};

export default StatsGeneral;
