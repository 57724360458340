import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const depositPageStyles = {
    tabList: css`
        display: flex;
        border-bottom: 2px solid ${colors.darkPrimary};
    `,
    tabItem: css`
        padding: 12px 16px;
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
        &.active {
            background: ${colors.darkPrimary};
        }
    `,
    bankGroup: css`
        padding: 16px 0;
        border-bottom: 1px solid ${colors.borderTable};
    `,
    titleBankGroup: css`
        margin-bottom: 8px;
        font-weight: 400;
        span {
            color: ${colors.yellow};
            font-weight: 500;
        }
    `,
    bankList: css`
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        min-height: 57px;
    `,
    bankItem: css`
        padding: 4px;
        background: ${colors.drak50};
        border-radius: 12px;
        border: 2px solid ${colors.gray};
        cursor: pointer;
        &:hover,
        &.active {
            border-color: #00ffff;
            > div {
                border-color: ${colors.primary};
            }
        }
    `,
    bankItemInner: css`
        background: ${colors.white};
        border-radius: 6px;
        padding: 4px 8px;
        border: 2px solid transparent;
        position: relative;
    `,
    bankIcon: css`
        width: 80px;
        height: 35px;
        object-fit: contain;
    `,
    iconBankSelect: css`
        position: absolute;
        right: -2px;
        bottom: -2px;
    `,
    iframeDeposit: css`
        height: 700px;
        margin-top: 16px;
        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    `,
    channelMaintain: css`
        font-size: 20px;
        text-align: center;
        padding: 40px 0;
        img {
            width: 150px;
        }
    `,
};
export default depositPageStyles;
