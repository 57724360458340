import { css } from '@emotion/css';
import classNames from 'classnames';
import Button from 'components/button/Button';
import TagLabel from 'components/tag-label/TagLabel';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { BankDepositModel, EWalletOrderDetail } from 'models/bankModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { numberWithCommas } from 'utils/time-utils';
import depositPageStyle from './deposit.styles';
const cdnUrl = process.env.REACT_APP_CDN_URL;

const listAmountQuick = [
    {
        value: 200000,
        label: '200K',
    },
    {
        value: 500000,
        label: '500K',
    },
    {
        value: 1000000,
        label: '1TR',
    },
    {
        value: 2000000,
        label: '2TR',
    },
    {
        value: 5000000,
        label: '5TR',
    },
    {
        value: 10000000,
        label: '10TR',
    },
    {
        value: 20000000,
        label: '20TR',
    },
    {
        value: 50000000,
        label: '50TR',
    },
    {
        value: 100000000,
        label: '100TR',
    },
    {
        value: 300000000,
        label: '300TR',
    },
];

interface Props {
    bank3rdList: BankDepositModel[];
    onCreateOrder: (payload: { amount: number; type: string }) => void;
    eWalletOrder: EWalletOrderDetail | null;
    onCancelOrder: (orderId: string) => void;
    onComplete: (orderId: string) => void;
}

export default function EWalletDeposit(props: Props) {
    const { bank3rdList, onCreateOrder, eWalletOrder, onCancelOrder, onComplete } = props;
    const [bankSelect, setBankSelect] = useState<BankDepositModel>({} as BankDepositModel);
    const [amountDeposit, setAmountDeposit] = useState('');
    const [amountHintList, setAmountHintList] = useState(listAmountQuick);

    useEffect(() => {
        if (Object.keys(bankSelect).length > 0) {
            const hintAmount = listAmountQuick.filter((item) => item.value >= bankSelect.minamount && item.value <= bankSelect.maxamount);
            setAmountHintList(hintAmount);
        }
    }, [bankSelect]);

    const selectBank = (bankItem: BankDepositModel) => {
        setBankSelect(bankItem);
    };

    const submitDeposit = async () => {
        if (Object.keys(bankSelect).length <= 0) {
            toast.error('Vui lòng chọn ví thanh toán');
            return;
        }
        if (!amountDeposit) {
            toast.error('Vui lòng chọn số tiền muốn nạp');
            return;
        }
        const amount = Number(amountDeposit.replace(/[,]/g, ''));

        if (amount < bankSelect.minamount) {
            toast.error(`Tối thiểu nạp ${numberWithCommas(bankSelect.minamount)} VNĐ`);
            return;
        }

        if (amount > bankSelect.maxamount) {
            toast.error(`Tối đa nạp ${numberWithCommas(bankSelect.maxamount)} VNĐ`);
            return;
        }

        const payload = {
            amount,
            type: bankSelect.bankcode,
        };
        onCreateOrder(payload);
    };

    const mapIconBank = (bankIcon: string) => {
        if (bankIcon.includes('.jpg') || bankIcon.includes('.png')) {
            return `${cdnUrl}/bank-list/${bankIcon}`;
        } else {
            return `${cdnUrl}/bank-list/${bankIcon}.jpg`;
        }
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Sao chép thành công!');
    };

    const getIconEwallet = (type: string) => {
        return require(`assets/images/ewallet/${type}.png`);
    };

    if (eWalletOrder) {
        return (
            <>
                <div className={styles.depositContainer}>
                    <div className={styles.depositBankLeft}>
                        <div>
                            <h6 className={styles.optionTitle}>
                                Thông tin thanh toán qua ví <span>{eWalletOrder.type}</span>
                            </h6>
                            <div className={styles.bankInfo}>
                                <div className={styles.bankInfoItem}>
                                    <p>Tài khoản nhận</p>
                                    <div className={styles.bankInfoValue}>
                                        <p>{eWalletOrder.phone_num}</p>
                                        <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(eWalletOrder.phone_num)}>
                                            <img src={require('assets/images/icons/copy.png')} alt="" />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.bankInfoItem}>
                                    <p>Tên người nhận</p>
                                    <div className={styles.bankInfoValue}>
                                        <p>{eWalletOrder.phone_name}</p>
                                        <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(eWalletOrder.phone_name)}>
                                            <img src={require('assets/images/icons/copy.png')} alt="" />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.bankInfoItem}>
                                    <p>Nội dung chuyển khoản</p>
                                    <div className={styles.bankInfoValue}>
                                        <p>{eWalletOrder.code}</p>
                                        <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(eWalletOrder.code)}>
                                            <img src={require('assets/images/icons/copy.png')} alt="" />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.bankInfoItem}>
                                    <p>Số tiền thanh toán</p>
                                    <div className={styles.bankInfoValue}>
                                        <p style={{ color: `${colors.secondary}`, fontWeight: 600 }}>{new Intl.NumberFormat().format(eWalletOrder.amount)}</p>
                                        <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(String(eWalletOrder.amount))}>
                                            <img src={require('assets/images/icons/copy.png')} alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {eWalletOrder.qr_url && (
                            <div style={{ marginTop: '24px' }}>
                                <h6 className={styles.optionTitle}>Hoặc quét mã QR CODE để nạp tiền</h6>
                                <div className={styles.qrDeposit}>
                                    <p>Sử dụng ứng dụng ngân hàng thực hiện giao dịch. Vui lòng hướng camera vào QR code để lấy thông tin nạp tiền.</p>
                                    <div className={styles.qrImgWrap}>
                                        <img className={styles.qrImg} src={eWalletOrder.qr_url} alt="" />
                                        <img className={styles.qrLogo} src={require('assets/images/logos/qr.png')} alt="" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.depositBankRight}>
                        <div className={styles.iconWalletWrap}>
                            <img className="icon-wallet" src={getIconEwallet(eWalletOrder.type)} alt="" />
                        </div>
                        <div className={styles.rightSection}>
                            <h6>Hướng Dẫn</h6>

                            <ol>
                                <li>
                                    Sử dụng ví <span style={{ color: colors.secondary }}>{eWalletOrder.type}</span> để thanh toán.
                                </li>
                                <li>
                                    Để tiền được cập nhật nhanh chóng, quý khách vui lòng điền chính xác <span style={{ color: colors.secondary }}>Nội dung chuyển khoản</span>.
                                </li>
                                <li>Không được sửa đổi số tiền.</li>
                                <li>
                                    Nhấn <span style={{ color: colors.secondary }}>Hoàn thành</span> sau khi đã chuyển khoản thành công.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className={styles.cancelOrder}>
                    <Button text="Huỷ Lệnh" bg={colors.darkRed} minWidth="160px" borderColor={colors.darkRed} click={() => onCancelOrder(eWalletOrder.id)} bgHover="#D00C00" />
                    <Button
                        text="Hoàn thành"
                        bg={colors.secondary}
                        minWidth="160px"
                        borderColor={colors.secondary}
                        click={() => onComplete(eWalletOrder.id)}
                        bgHover="#ff9900"
                        textColor={colors.blackText}
                    />
                </div>
            </>
        );
    }

    return bank3rdList.length > 0 ? (
        <div className={styles.wrap3rd}>
            {bankSelect.name ? (
                <h6 className={depositPageStyle.titleBankGroup}>
                    1. Bạn đang chọn ví <span>{bankSelect.name}</span>
                </h6>
            ) : (
                <h6 className={depositPageStyle.titleBankGroup}>1 .Chọn ví thanh toán</h6>
            )}
            <div className={depositPageStyle.bankList}>
                {bank3rdList.map((bank, index) => (
                    <div className={classNames(depositPageStyle.bankItem, { active: bankSelect.bankcode === bank.bankcode })} key={index} onClick={() => selectBank(bank)}>
                        <div className={depositPageStyle.bankItemInner}>
                            {bank.icon.includes('http') ? (
                                <img className={`${depositPageStyle.bankIcon}`} src={bank.icon} alt="" />
                            ) : (
                                <img className={`${depositPageStyle.bankIcon}`} src={mapIconBank(bank.icon)} alt="" />
                            )}
                            {bankSelect.bankcode === bank.bankcode && <img className={depositPageStyle.iconBankSelect} src={require('assets/images/icons/tick-bank.png')} alt="" />}
                        </div>
                    </div>
                ))}
            </div>
            {Object.keys(bankSelect).length > 0 && (
                <>
                    <div className={styles.wrap}>
                        <div className={styles.left}>
                            <h6 className={depositPageStyle.titleBankGroup}>2. Chọn số tiền nạp</h6>
                            <div className={styles.amountList}>
                                {amountHintList.map((item) => (
                                    <div className={styles.amountItem} key={item.value}>
                                        <p className={styles.amountItemInner} onClick={() => setAmountDeposit(String(item.value))}>
                                            {item.label}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.right}>
                            <h6 className={depositPageStyle.titleBankGroup}>Hoặc nhập số tiền nạp (VNĐ)</h6>
                            <NumericFormat
                                allowNegative={false}
                                className="input-common"
                                thousandSeparator=","
                                placeholder="Nhập số tiền..."
                                value={amountDeposit}
                                onChange={(e) => setAmountDeposit(e.target.value)}
                            />
                            <div style={{ marginTop: '8px' }}>
                                <TagLabel label={`Tối thiểu ${new Intl.NumberFormat().format(bankSelect.minamount)} - Tối đa ${new Intl.NumberFormat().format(bankSelect.maxamount)}`} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 'calc(60% - 50px)', marginTop: '30px' }}>
                        <button type="button" className={globalStyle.btnPrimary} onClick={submitDeposit}>
                            Gửi Lệnh
                        </button>
                    </div>
                </>
            )}
        </div>
    ) : (
        <div className={styles.mainTain}>
            <img src={require('assets/images/icons/bank-maintain.png')} alt="" />
            <p>Hệ thống đang bảo trì.</p>
        </div>
    );
}

const styles = {
    wrap3rd: css`
        margin-top: 16px;
    `,
    wrap: css`
        display: flex;
        align-items: flex-start;
        gap: 50px;
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid ${colors.borderTable};
    `,
    left: css`
        flex: 0 0 calc(60% - 50px);
    `,
    amountList: css`
        display: flex;
        flex-wrap: wrap;
    `,
    amountItem: css`
        padding: 4px;
        flex: 0 0 20%;
        max-width: 20%;
    `,
    amountItemInner: css`
        padding: 8px 0;
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        cursor: pointer;
        text-align: center;

        &:active {
            background: ${colors.primary};
        }
    `,
    right: css`
        flex: 0 0 40%;
    `,

    depositContainer: css`
        display: flex;
        margin-top: 8px;
        gap: 40px;
    `,
    depositBankLeft: css`
        flex: 0 0 400px;
    `,
    depositBankRight: css`
        padding: 16px;
        flex: 1;
        margin-top: 24px;
        ol,
        ul {
            padding-left: 16px;
            font-size: 14px;
            li {
                line-height: 1.4;
                margin-bottom: 4px;
            }
            span {
                color: ${colors.yellow};
            }
        }
    `,
    iconWalletWrap: css`
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
        .icon-wallet {
            padding: 8px;
            background: ${colors.white};
            border-radius: 16px;
        }
    `,
    rightSection: css`
        margin-bottom: 24px;
        h6 {
            font-weight: 400;
            margin-bottom: 8px;
        }
    `,
    optionTitle: css`
        margin-bottom: 8px;
        font-weight: 400;

        span {
            color: ${colors.yellow};
            font-weight: 500;
        }
    `,
    bankInfo: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: ${colors.drak50};
        padding: 12px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
    `,
    bankInfoItem: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    bankInfoValue: css`
        display: flex;
        align-items: center;
        gap: 8px;
    `,
    qrDeposit: css`
        background: ${colors.drak50};
        padding: 12px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
        display: flex;
        align-items: center;
        gap: 12px;
        p {
            font-size: 14px;
            color: #ccc;
        }
    `,
    textQrCode: css``,
    qrImgWrap: css`
        flex: 0 0 120px;
        height: 120px;
        position: relative;
    `,
    qrImg: css`
        width: 100%;
    `,
    qrLogo: css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;

        transform: translate(-50%, -50%);
    `,
    cancelOrder: css`
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: 24px;
        height: 44px;
    `,
    mainTain: css`
        text-align: center;
        font-size: 20px;
        padding: 40px 0;
        img {
            width: 150px;
        }
    `,
};
