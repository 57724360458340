import lotteryApi from 'apis/lotteryApi';
import alertMsg from 'components/alert-message/alert-message';
import { BetInfo, BetRecordDetailResponse, BetSummary, FaceLottery, LotteryInterface, LotterySub, LotteryTicket, PrizeLotteryResponse, TypeInputLottery } from 'models/lotteryModel';
import toast from 'react-hot-toast';
import { cloneObject, splitInputLottery } from 'utils/function';

interface IGroupDualNumber {
    number: string;
    multiple: number;
    issue: string;
    methodID: number;
    lotteryId: number;
}

export const mapPrizeIntoFaceLottery = (faceLottery: FaceLottery, userPrize: PrizeLotteryResponse[], faceContent: LotteryInterface[]) => {
    const userPrizeObject: { [key: string]: PrizeLotteryResponse } = {};
    userPrize.forEach((item) => {
        userPrizeObject[item.methodid] = item;
    });
    let methodList = faceLottery.methodList,
        methodidInDBList = faceLottery.methodidInDBList,
        method1kList = faceLottery.method1kList;

    const newFaceContent: any[] = faceContent.map((item) => {
        if (item.classicStyle) {
            if (item.rootCol) {
                return item.rootCol.map((rcol) => {
                    return rcol.subCol.map((scol) => {
                        const upObj = userPrizeObject[scol.methodidInDB || ''];
                        scol.dyprize = upObj.dyprize[0].prize[1];
                        scol.dyprize.prize = parseFloat(scol.dyprize.prize).toFixed(2);
                        scol.payprizenum = Number(upObj.payprizenum);
                        scol.rebate = `0|${scol.dyprize.prize}`;
                        scol.methodID = Number(upObj.methodidInDB);
                        return scol;
                    });
                });
            }
            return item;
        } else {
            if (item.subTabs) {
                const newSubTab: any[] = [];
                item.subTabs.forEach((method) => {
                    let upObj = userPrizeObject[method.methodidInDB || ''];
                    method.dyprize = upObj.dyprize[0].prize[1];
                    method.dyprize.prize = parseFloat(method.dyprize.prize).toFixed(2);
                    method.payprizenum = Number(upObj.payprizenum);
                    method.rebate = `0|${method.dyprize.prize}`;
                    method.canBet1kMode = Boolean(upObj.methodid1k && upObj.payprizenum1k && upObj.methodid1k !== '0' && upObj.payprizenum1k !== '0');
                    method.methodID = Number(upObj.methodidInDB);
                    newSubTab.push(method);

                    let dataForBet1k = {
                        dyprize1k: upObj.dyprize[0].prize1k && upObj.dyprize[0].prize1k[0] ? cloneObject(upObj.dyprize[0].prize1k[0]) : null,
                        payprizenum: cloneObject(upObj.payprizenum),
                        payprizenum1k: upObj.payprizenum1k ? cloneObject(upObj.payprizenum1k) : null,
                        methodid: cloneObject(upObj.methodidInDB),
                        methodid1k: upObj.methodid1k ? cloneObject(upObj.methodid1k) : null,
                    };
                    if (dataForBet1k && dataForBet1k.dyprize1k) {
                        dataForBet1k.dyprize1k.prize = parseFloat(dataForBet1k.dyprize1k.prize).toFixed(2);
                    }
                    if (method.methodidInDB) {
                        methodList[method.methodidInDB] = cloneObject(method.dyprize);
                    }
                    if (method.methodidInDB) {
                        methodidInDBList[method.methodidInDB] = upObj.methodidInDB;
                    }
                    method1kList[method.methodID] = cloneObject(dataForBet1k);
                });
                return {
                    ...item,
                    subTabs: newSubTab,
                };
            }
            return item;
        }
    });

    return {
        content: newFaceContent,
        methodList,
        methodidInDBList,
        method1kList,
    };
};

export const genTicketInfo = (subTypeBetActive: LotterySub, typeInputBet: TypeInputLottery[]) => {
    let pickType = '';
    let ticketType = '';
    let curCodes: string[] = [];
    const isXienTruot = subTypeBetActive.isXienTruot;

    typeInputBet.forEach((playType) => {
        if (playType.active && playType.typeIputID === 'pick') {
            pickType = playType.typeIputID;
            ticketType = playType.type;
            playType.layout.forEach((playLayout) => {
                if (playLayout.selectedNums.length !== 0) {
                    // (['running', 'dau-duoi', '1-digits'].includes(self.gameTabs[self.activeLotteryID].content[self.activeTabroot].typeIputID))
                    //   ? curCodes.push(playLayout.selectedNums.join('|'))
                    curCodes.push(playLayout.selectedNums.join('&'));
                }
            });
        }

        if (playType.active && playType.typeIputID === 'quick-pick') {
            pickType = playType.typeIputID;
            ticketType = playType.type;
            playType.layout.forEach((playLayout) => {
                if (playLayout.selectedNums.length !== 0) {
                    if (isXienTruot) {
                        curCodes.push(playLayout.selectedNums.join('&'));
                    } else {
                        curCodes.push(...playLayout.selectedNums);
                        curCodes = [curCodes.join(',')];
                    }
                }
            });
        }
        if (playType.active && playType.typeIputID === 'type-in') {
            pickType = playType.typeIputID;
            ticketType = playType.type;
            playType.layout.forEach((playLayout) => {
                if (playLayout.selectedNums.length !== 0) {
                    curCodes.push(playLayout.selectedNums.join(','));
                }
            });
            curCodes = playType.layout[0].selectedNums;
            curCodes[0] = curCodes[0] ? splitInputLottery(curCodes[0]).join(',') : '';
            if (isXienTruot) {
                curCodes[0] = curCodes[0].includes(' ') ? curCodes[0].replace(/ /g, ';') : curCodes[0];
                curCodes[0] = curCodes[0].includes(',') ? curCodes[0].replace(/;/g, ';') : curCodes[0];
            } else {
                curCodes[0] = curCodes[0].includes(' ') ? curCodes[0].replace(/ /g, ',') : curCodes[0];
                curCodes[0] = curCodes[0].includes(';') ? curCodes[0].replace(/;/g, ',') : curCodes[0];
            }
        }
    });
    return {
        picktype: pickType,
        tickettype: ticketType,
        curcodes: curCodes,
    };
};

export const validateRule1PercentOnOriginalTickets = (bettingTickets: LotteryTicket[], gameTabDetails: FaceLottery) => {
    if (!bettingTickets || !bettingTickets.length || !gameTabDetails || !gameTabDetails.content || !gameTabDetails.content.length) return [];
    let lotteryHasRule = gameTabDetails.hasRule1Percent;
    if (!lotteryHasRule) return [];
    let allMethodIdsWithRule: any[] = [];
    gameTabDetails.content.forEach((item: any) => {
        if (item && item.subTabs && item.subTabs.length) {
            allMethodIdsWithRule = allMethodIdsWithRule.concat(item.subTabs.filter((game: any) => game.rule1Percent));
        }
    });
    if (!allMethodIdsWithRule.length) return [];
    let invalidMethodIds = [];
    let mapIds: any = {};
    allMethodIdsWithRule.forEach((item) => {
        if (item) {
            mapIds[item.methodID] = item.rule1Percent;
        }
    });
    invalidMethodIds = bettingTickets.filter((ticket) => mapIds[ticket.methodID] && mapIds[ticket.methodID] > ticket.total).map((ticket) => Number(ticket.methodID));
    return invalidMethodIds;
};

const flagCheckDualNumLottery = (lotteryID: number, methodID: number) => {
    let flag = false;
    if (
        (Number(lotteryID) === 200 || Number(lotteryID) === 201 || Number(lotteryID) === 202 || Number(lotteryID) === 89 || Number(lotteryID) === 206) &&
        (Number(methodID) === 6 ||
            Number(methodID) === 21 ||
            Number(methodID) === 1000 ||
            Number(methodID) === 23 ||
            Number(methodID) === 2026 ||
            Number(methodID) === 20221 ||
            Number(methodID) === 2021000 ||
            Number(methodID) === 20223 ||
            Number(methodID) === 896 ||
            Number(methodID) === 8921 ||
            Number(methodID) === 8923)
    ) {
        flag = true;
    }
    return flag;
};

const checkDualNumber = async (lotteryId: number, issue: string, cb: (data: BetRecordDetailResponse[]) => void) => {
    let issueArr = [];

    issueArr = ["'" + issue + "'"];
    const rqParams = {
        id: lotteryId,
        issue: issueArr.join(','),
    };
    try {
        const { data } = await lotteryApi.lotteryCheckDualNumber(rqParams);
        if (!data.msg) {
            const arrHis: BetRecordDetailResponse[] = data.reslist;
            const arrCode = arrHis.filter((item) => {
                if (flagCheckDualNumLottery(item.lotteryid, item.methodid)) {
                    return item;
                }
            });
            cb && cb(arrCode);
        } else {
            toast.error(data.msg);
        }
    } catch (error) {
        console.log(error);
    }
};

/**
 * return bet summary to able to submit to server
 */
export const genBetSummary = (lotteryId: number, issue: string, ticketList: LotteryTicket[]) => {
    let betSummary: BetSummary = {
        lotteryid: Number(lotteryId),
        flag: '',
        lt_sel_times: 1,
        lt_sel_modes: 1,
        lt_issue_start: issue,
        lt_total_nums: 0,
        lt_project: [],
        lt_total_money: 0,
        lt_sel_dyprize: null,
    };

    const betInfo: BetInfo = {
        type: '',
        methodid: 0,
        zip: 0,
        nums: 0,
        times: 0,
        money: 0,
        mode: 1,
        point: 0,
        curtimes: new Date().getTime(),
        desc: '',
        codes: '',
        ratio: 0,
        remark: null,
    };

    ticketList.forEach((ticket) => {
        let betInfoExt = JSON.parse(JSON.stringify(betInfo));
        betInfoExt.type = ticket.type;
        betInfoExt.methodid = ticket.methodID;
        betInfoExt.nums = ticket.total;
        betInfoExt.times = ticket.times;
        betInfoExt.codes = ticket.codes;
        betInfoExt.money = ticket.money * ticket.times;
        betInfoExt.point = ticket.dyprize.point;
        betInfoExt.desc = `[${ticket.trTitle} _ ${ticket.stTitle}${ticket.isBet1k && ticket.methodid1k ? ' 1k' : ''}] ${ticket.codes.replace(/&/g, ',')}`;
        betInfoExt.ratio = ticket.dyprize.prize;
        betInfoExt.remark = ticket.rebate.split('|')[0] === '0' ? null : `rebate_${ticket.rebate.split('|')[0]}`;
        betInfoExt.lotteryid = lotteryId;
        // if (this.gameTabs[this.activeLotteryID].content[this.activeTabroot].subTabs[this.activeSubtab].payprizeMoney) {
        //     betInfoExt.remark = null;
        // }

        betSummary.lt_total_nums += ticket.total;
        betSummary.lt_total_money += ticket.money * ticket.times;

        if (ticket.isBet1k && ticket.methodid1k) {
            betInfoExt.isBet1k = true;
            betInfoExt.methodid1k = ticket.methodid1k;
        }
        betSummary.lt_project.push(JSON.stringify(betInfoExt));

        //reset point into 0
        // if (Number(ticket.dyprize.prize.point) !== 0) {
        //     ticket.dyprize.point = 0;
        // }
        betSummary.lt_sel_dyprize = `${ticket.dyprize.point}|${ticket.dyprize.prize}`;
        betSummary.betFrom = 3; //3: web, 2: android, 1: ios
    });

    return betSummary;
};

/**
 * @param codes = "5&3|5" || "55, 35"
 */
const createGroupDualNum = (codes: string) => {
    let strTempOutCome = [];
    if (codes.search(/[|]/g) >= 0) {
        const checkSymbol = codes.split('|');
        const arrTemp: string[][] = [];
        checkSymbol.forEach((itemCheck) => {
            arrTemp.push(itemCheck.split('&'));
        });
        strTempOutCome = getDualNumber(allPossibleCases(arrTemp));
    } else {
        strTempOutCome = getDualNumber(codes.split(','));
    }
    return strTempOutCome;
};

const allPossibleCases = (arr: string[][]) => {
    if (arr.length === 1) {
        return arr[0];
    } else {
        const result = [];
        const allCasesOfRest: string[] = allPossibleCases(arr.slice(1)); // recur with the rest of array
        for (let i = 0; i < allCasesOfRest.length; i++) {
            for (let j = 0; j < arr[0].length; j++) {
                result.push(arr[0][j] + allCasesOfRest[i]);
            }
        }
        return result;
    }
};

/**
 * check so co ton tai kep bang
 * @param arrNumber = ["00", "12", "11", "122", "455", "10", "14",...]
 * @returns {any["00", "11", "122", "455"]}
 */
const getDualNumber = (arrNumber: string[]) => {
    const amountCheck: string[] = [];
    arrNumber.forEach((num) => {
        const strNum = num.toString().trim();
        const numberTwoDigits = strNum.substring(strNum.length - 2); // get last digit number
        const splitNum = numberTwoDigits.split('');
        const getFirstNum = splitNum[0];
        let countCheck = 0;
        for (let i = 1; i < splitNum.length; i++) {
            if (getFirstNum === splitNum[i]) {
                countCheck += 1;
            }
        }

        if (countCheck + 1 === splitNum.length) {
            amountCheck.push(num);
        }
    });
    return amountCheck;
};

/**
 * @param arrDualNum = [{number: string, multiple: number, issue: string}]
 */

const groupMultipleDualNum = (arrDualNum: IGroupDualNumber[]) => {
    let flag = true;
    const i = 0;
    let j = 1;
    let countDualNum = 0;
    const arrTempDualNum: IGroupDualNumber[] = [];

    if (arrDualNum.length <= 0) {
        return arrTempDualNum;
    }

    while (flag) {
        if (arrDualNum.length > 1 && arrDualNum[i].number === arrDualNum[j].number && arrDualNum[i].issue === arrDualNum[j].issue && arrDualNum[i].methodID === arrDualNum[j].methodID) {
            countDualNum += arrDualNum[j].multiple;
            arrDualNum.splice(j, 1);
            j -= 1;
        }
        j += 1;
        if (j >= arrDualNum.length) {
            countDualNum += arrDualNum[i].multiple;
            arrTempDualNum.push({
                number: arrDualNum[i].number,
                multiple: countDualNum,
                issue: arrDualNum[i].issue,
                methodID: arrDualNum[i].methodID,
                lotteryId: arrDualNum[i].lotteryId,
                // lotteryName: arrDualNum[i].lotteryName,
            });
            arrDualNum.splice(i, 1);
            j = 1;
            countDualNum = 0;
            if (arrDualNum.length === 0) {
                flag = false;
            }
        }
        if (arrDualNum.length === 1) {
            arrTempDualNum.push(arrDualNum[i]);
            flag = false;
        }
    }

    return arrTempDualNum;
};

export const checkMB_DeDacBiet_3CangDacBiet = (fast: boolean, lotteryId: number, issue: string, ticketList: LotteryTicket[], callback: (data: { passed: boolean; validateInfo?: any[] }) => void) => {
    if (
        Number(lotteryId) === 200 || //mien bac
        Number(lotteryId) === 201 || //mien bac 18h25
        Number(lotteryId) === 202 || // ha noi vip
        Number(lotteryId) === 89 ||
        Number(lotteryId) === 206 //vietnam vip
    ) {
        checkDualNumber(lotteryId, issue, (arrCode) => {
            const arrGroupDualNumOld: IGroupDualNumber[] = [];
            let arrGroupDualNumLast: IGroupDualNumber[] = [];
            const betParams = genBetSummary(lotteryId, issue, ticketList);
            const lt_project = betParams.lt_project;
            arrCode.forEach((itemCode) => {
                const codes = itemCode.codes;
                const getMultiple = itemCode.multiple;
                const getIssue = itemCode.issue;
                const getDualNums = createGroupDualNum(codes);
                getDualNums.forEach((dualNum) => {
                    const dualObj: IGroupDualNumber = {
                        number: dualNum,
                        multiple: getMultiple,
                        issue: getIssue,
                        methodID: Number(itemCode.methodid),
                        lotteryId: Number(lotteryId),
                    };
                    arrGroupDualNumOld.push(dualObj);
                });
            });
            lt_project.forEach((itemCode) => {
                const item = JSON.parse(itemCode.replace(/'/g, '"'));
                if (flagCheckDualNumLottery(betParams.lotteryid, item.methodid)) {
                    const getMultiple = item.times;
                    const codes = item.codes;
                    const getDualNums = createGroupDualNum(codes);
                    getDualNums.forEach((dualNum) => {
                        arrGroupDualNumLast.push({
                            number: dualNum,
                            multiple: getMultiple,
                            issue: String(betParams.lt_issue_start),
                            methodID: Number(item.methodid),
                            lotteryId: Number(lotteryId),
                        });
                    });
                }
            });

            // if (ltTraceTimes.length > 0) {
            //     const arrTempLast = [];
            //     ltTraceTimes.forEach((item) => {
            //         arrGroupDualNumLast.forEach((itemDualNum) => {
            //             arrTempLast.push({
            //                 number: itemDualNum.number,
            //                 multiple: item.value * itemDualNum.multiple,
            //                 issue: item.name,
            //                 methodID: Number(itemDualNum.methodID),
            //             });
            //         });
            //     });
            //     arrGroupDualNumLast = arrTempLast;
            // }

            const groupMultiOld = groupMultipleDualNum(arrGroupDualNumOld);
            const groupMultiLast = groupMultipleDualNum(arrGroupDualNumLast);
            const arrTempMulti = groupMultipleDualNum(groupMultiOld.concat(groupMultiLast) as IGroupDualNumber[]);
            const getMultiDB = arrTempMulti.filter((itemMulti) => {
                return Number(itemMulti.methodID) === 6 || Number(itemMulti.methodID) === 1000 || Number(itemMulti.methodID) === 2026 || Number(itemMulti.methodID) === 2021000;
            });
            let getMaxMultiDB = 0;
            if (getMultiDB.length > 0) {
                getMaxMultiDB = Math.max.apply(
                    Math,
                    getMultiDB.map((o) => {
                        return o.multiple;
                    })
                );
            }

            const getMulti3Cang = arrTempMulti.filter((itemMulti) => {
                return Number(itemMulti.methodID) === 21 || Number(itemMulti.methodID) === 20221;
            });
            let getMaxMulti3Cang = 0;
            if (getMulti3Cang.length > 0) {
                getMaxMulti3Cang = Math.max.apply(
                    Math,
                    getMulti3Cang.map((o) => {
                        return o.multiple;
                    })
                );
            }

            const getMulti4CangDB = arrTempMulti.filter((itemMulti) => {
                return Number(itemMulti.methodID) === 23 || Number(itemMulti.methodID) === 20223;
            });
            let getMaxMulti4CangDB = 0;
            if (getMulti4CangDB.length > 0) {
                getMaxMulti4CangDB = Math.max.apply(
                    Math,
                    getMulti4CangDB.map((o) => {
                        return o.multiple;
                    })
                );
            }
            // 2000 là số tối đa cho đánh số kép bằng cho mỗi số
            if (getMaxMultiDB > 2000 || getMaxMulti3Cang > 200 || getMaxMulti4CangDB > 20) {
                arrTempMulti.sort((a, b) => {
                    return b.multiple - a.multiple;
                }); // sort z-a
                const validateInfoList: any[] = [];
                arrTempMulti.forEach((item) => {
                    let isValid = true;
                    if (
                        (Number(item.multiple) > 2000 && (Number(item.methodID) === 6 || Number(item.methodID) === 1000)) ||
                        (Number(item.multiple) > 200 && Number(item.methodID) === 21) ||
                        (Number(item.multiple) > 20 && Number(item.methodID) === 23) ||
                        (Number(item.multiple) > 2000 && (Number(item.methodID) === 2026 || Number(item.methodID) === 2021000)) ||
                        (Number(item.multiple) > 200 && Number(item.methodID) === 20221) ||
                        (Number(item.multiple) > 20 && Number(item.methodID) === 20223)
                    ) {
                        isValid = false;
                    }
                    let pointFirst = 0;
                    let pointLast = 0;
                    const findExistOld = groupMultiOld.find((dualNumOld) => {
                        return dualNumOld.number === item.number && dualNumOld.issue === item.issue;
                    });
                    const findExistLast = groupMultiLast.find((dualNumLast) => {
                        return dualNumLast.number === item.number && dualNumLast.issue === item.issue;
                    });
                    if (findExistOld) {
                        pointFirst = findExistOld.multiple;
                    }
                    if (findExistLast) {
                        pointLast = findExistLast.multiple;
                    }
                    let pointRecent = 0;
                    let maxDualNum = 0;
                    if (Number(item.methodID) === 6 || Number(item.methodID) === 1000 || Number(item.methodID) === 2026 || Number(item.methodID) === 2021000) {
                        pointRecent = 2000 - pointFirst;
                        maxDualNum = 2000;
                    } else if (Number(item.methodID) === 21 || Number(item.methodID) === 20221) {
                        pointRecent = 200 - pointFirst;
                        maxDualNum = 200;
                    } else if (item.methodID === 23 || item.methodID === 20223) {
                        pointRecent = 20 - pointFirst;
                        maxDualNum = 20;
                    }

                    const validateInfo = {
                        betted: {
                            value: pointFirst,
                            max: maxDualNum,
                        },
                        recentValid: pointRecent,
                        bettingNum: pointLast,
                        numberBet: item.number,
                        issue: item.issue,
                        isValid,
                    };
                    validateInfoList.push(validateInfo);
                });

                if (fast) {
                    // this.gameTabs[activeLotteryID].tickets = [];
                }
                callback && callback({ passed: false, validateInfo: validateInfoList });
            } else {
                // this.callSubmitBet(fast, raise, data);
                callback && callback({ passed: true });
            }
        });
    } else {
        // this.callSubmitBet(fast, raise, data);
        callback && callback({ passed: true });
    }
};

export const updateMethodIdWithinBet1kBeforeSubmit = (betParamsToSubmit: any) => {
    let result = cloneObject(betParamsToSubmit);
    let tickets = result.lt_project.map((x: any) => JSON.parse(x));
    tickets = tickets.map((ticket: any) => {
        let cloneTicket = JSON.parse(JSON.stringify(ticket));
        if (cloneTicket.isBet1k && cloneTicket.methodid1k) {
            cloneTicket.methodid = JSON.parse(JSON.stringify(cloneTicket.methodid1k));
            delete cloneTicket.isBet1k;
            delete cloneTicket.methodid1k;
        }
        return cloneTicket;
    });

    tickets = tickets.map((item: any) => ({
        ...item,
        point: 0,
        lotteryid: result.lotteryid,
    }));
    result.lt_project = tickets.map((x: any) => JSON.stringify(x));
    return result;
};

export const checkValidTypeNum = (slNums: string, isXienTruot: boolean, digits: string) => {
    let newSlNums = slNums;
    let isValid = false;
    if (isXienTruot) {
        const len1 = parseInt(digits.split(',')[0], 10);
        const len2 = parseInt(digits.split(',')[1], 10);
        // for (const num of slNums.split(splitChar)) {
        for (const num of splitInputLottery(slNums)) {
            if (num.includes('&')) {
                if (num.split('&').length === len2) {
                    if (num.length === len1 * len2 + len2 - 1 && (slNums.match(new RegExp(num, 'g')) || []).length > 1) {
                        isValid = false;
                        alertMsg('Trùng vé cược, vui lòng chọn lại!', 'error', true);
                        newSlNums = slNums.slice(0, -1 * (len1 * len2 + len2 - 1) - 1);
                        break;
                    }
                    for (const nm of num.split('&')) {
                        if (!Number.isNaN(Number(nm)) && nm.length === len1) {
                            isValid = true;
                            if ((num.match(new RegExp(nm, 'g')) || []).length > 1) {
                                isValid = false;
                                alertMsg('Trùng vé cược, vui lòng chọn lại!', 'error', true);
                                newSlNums = slNums.slice(0, -1 * len1);

                                break;
                            }
                        } else {
                            isValid = false;
                            break;
                        }
                    }
                } else {
                    isValid = false;
                    break;
                }
            } else {
                isValid = false;
                break;
            }
        }
    } else {
        const len = parseInt(digits.split(',')[0], 10);
        for (const num of splitInputLottery(slNums)) {
            if (!Number.isNaN(Number(num)) && num.length === len) {
                isValid = true;
                if ((slNums.match(new RegExp(num, 'g')) || []).length > 1) {
                    isValid = false;
                    alertMsg('Trùng vé cược, vui lòng chọn lại!', 'error', true);
                    newSlNums = slNums.slice(0, -1 * len - 1);

                    break;
                }
            } else {
                isValid = false;
                break;
            }
        }
    }
    const amount = isValid ? splitInputLottery(slNums).length : 0;
    return {
        isValid,
        slNums: newSlNums,
        amount,
    };
};
