import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const authApi = {
    login(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'LoginBean_loginnew';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    signUp(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'RegBean_regNew';
        return axiosClient.post(serviceType.REGISTER_SERVICE, payload);
    },

    getUserInfo(): Promise<AxiosResponse<BaseResponse<any>>> {
        return axiosClient.post(serviceType.USER_SERVICE, { flag: 'GetUserItem' });
    },
    loginGetToken(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/login.aspx';
        return axiosClient.post(url, payload);
    },
    refreshToken(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/refreshToken.aspx';
        return axiosClient.post(url, payload);
    },
    logoutByToken(): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/logout.aspx';
        return axiosClient.post(url);
    },

    checkUserValid(payload: { username: string; phone: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/forgot-password/check-user.aspx';
        return axiosClient.post(url, payload);
    },

    verifyUser(payload: { username: string; phone: string; token: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/forgot-password/verify-token.aspx';
        return axiosClient.post(url, payload);
    },
    changePassLogin(payload: { token: string; newPassword: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        const url = 'authentication/forgot-password/changed-password.aspx';
        return axiosClient.post(url, payload);
    },
};
export default authApi;
